import React, {useEffect, useState} from "react";
import "./SearchResultComponent.css"
import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg";
import SearchResultItemComponent from "./SearchResultItemComponent";
import { Form, TextArea } from 'semantic-ui-react'
import {saveWordToLocalStorageArray} from "../../../utils/saveWordToLocalStorageArray";
import {removeWordFromLocalStorageArray} from "../../../utils/removeWordFromLocalStorageArray";

function SearchResultComponent(props) {
    const [tabActive, setTabActive] = useState(0);
    const [isBookmarked, setBookmarked] = useState(false);
    const [avBookmark, setAvBookmark] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);
    const [vaBookmark, setVaBookmark] = useState(localStorage.getItem('va_bookmark') ? JSON.parse(localStorage.getItem('va_bookmark')) : []);
    const [avNote, setAvNote] = useState(localStorage.getItem('av_note') ? JSON.parse(localStorage.getItem('av_note')) : []);
    const [vaNote, setVaNote] = useState(localStorage.getItem('va_note') ? JSON.parse(localStorage.getItem('va_note')) : []);
    const [note, setNote] = useState("");
    const [tabs, setTabs] = useState([
        {
            text: props.searchType.text,
            content: props.data.detail
        },
        {
            text: "Technical",
            content: props.data.tech
        },
        {
            text: "Anh - Anh",
            content: props.data.anhanh
        },
        {
            text: "Đồng nghĩa",
            content: props.data.dongnghia
        }
    ]);

    useEffect(() => {
        if(props.data.type === 0){
            setBookmarked(avBookmark.some(item => item.id === props.data.id))
        } else if(props.data.type === 1){
            setBookmarked(vaBookmark.some(item => item.id === props.data.id))
        }
    },[]);

    useEffect(()=>{
        if(props.data.type === 0){
            let index = avNote.findIndex(item => item.id === props.data.id);
            if(index !== -1){
                setNote(avNote[index].note)
            }
        } else if(props.data.type === 1){
            let index = vaNote.findIndex(item => item.id === props.data.id);
            if(index !== -1){
                setNote(vaNote[index].note)
            }
        }
    },[])

    useEffect(() => {
        if(props.data.type === 0){
            setAvBookmark(JSON.parse(localStorage.getItem('av_bookmark')) || [])
        } else if(props.searchType.id === 1){
            setVaBookmark(JSON.parse(localStorage.getItem('va_bookmark')) || [])
        }
    }, [isBookmarked])

    const onBookmark = () => {
        let vocabulary = {...props.data, ...{time: Date.now()}};
        if(props.data.type === 0){
            let index = avBookmark.findIndex(word => word.id === vocabulary.id);
            if(index === -1){
                saveWordToLocalStorageArray(vocabulary, 'av_bookmark');
                setBookmarked(true);
            } else {
                setBookmarked(false);
                removeWordFromLocalStorageArray(vocabulary, 'av_bookmark');
            }
        } else if(props.data.type === 1){
            let index = vaBookmark.findIndex(word => word.id === vocabulary.id);
            if(index === -1){
                saveWordToLocalStorageArray(vocabulary, 'va_bookmark');
                setBookmarked(true);
            } else {
                setBookmarked(false);
                removeWordFromLocalStorageArray(vocabulary, 'va_bookmark');
            }
        }
    };
    const onSaveNote = () => {
        let vocabulary = {...props.data, ...{time: Date.now(), note}};
        if(props.data.type === 0){
            let index = avNote.findIndex(item => item.id === props.data.id);
            if(index === -1){
                avNote.push(vocabulary);
                localStorage.setItem('av_note', JSON.stringify(avNote));
            } else {
                avNote[index] = vocabulary;
                localStorage.setItem('av_note', JSON.stringify(avNote));
            }
        } else if(props.data.type === 1){
            let index = vaNote.findIndex(item => item.id === props.data.id);
            if(index === -1){
                vaNote.push(vocabulary);
                localStorage.setItem('va_note', JSON.stringify(vaNote));
            } else {
                vaNote[index] = vocabulary;
                localStorage.setItem('va_note', JSON.stringify(vaNote));
            }
        }
    };

    return (
        <section id={"search-result"} style={props.isDisplay ? {} : { display: "none" }}>
            <div className={"search-result-container"}>
            <p className={"word-result"}>Kết quả: <span>{props.data.word}</span></p>
            <ul className={"tab-result"}>
                {
                    tabs.map((tab,index) => {
                        if(index !== 0 && tab.content === "") return null;
                        return (
                            <li key={index}>
                                <a href={"#"} className={tabActive === index ? "active" : ""}
                               onClick={(e)=> {e.preventDefault(); setTabActive(index)}}>{tab.text}</a>
                            </li>
                        )
                    })
                }

                <li><a href={"#"} className={tabActive === tabs.length + 1 ? "active" : ""}
                       onClick={(e) => {e.preventDefault(); setTabActive(tabs.length + 1)}}>Ghi chú</a> </li>
                <li className={"bookmark"}><a href={"#"} className={isBookmarked ? "active": ""}
                onClick={(e)=>{e.preventDefault(); onBookmark()}}>Đánh dấu <StarIcon/></a></li>
            </ul>
            <div className={"tab-content"}>
                {
                    tabActive < tabs.length ?
                        <SearchResultItemComponent searchType={props.searchType} content={tabs[tabActive].content}/>
                        : ""
                }
                <div style={tabActive === tabs.length + 1 ? {display: "block"}: {display: "none"}}>
                    <Form className={"note"}>
                        <TextArea rows="7" placeholder='Nội dung ghi chú của bạn ...' value={note}
                                  onChange={(e) => setNote(e.target.value)} />
                    </Form>
                    <div style={{display: "flex",justifyContent: "center"}}>
                        <button style={{
                            padding: "15px 58px",
                            borderRadius: "25px",
                            background: "var(--theme-primary)",
                            color: "#fff",
                            outline:"none",
                            cursor: "pointer",
                            border: "none"
                        }} onClick={() => onSaveNote()}>
                            Lưu
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
SearchResultComponent.defaultProps = {
    data: {
        word: null,
        detail: "",
        tech: "",
        anhanh: "",
        dongnghia: ""
    }
};

export default SearchResultComponent
