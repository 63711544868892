import React,{Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid, Image} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import './LessonItemComponent.css'
LessonItemComponent.propTypes = {

};

function LessonItemComponent(props) {
	const OnPracticeClick = ()=>{
		props.history.push(`/lesson/word/${props.code}/${props.id}/${props.name}`)
	}
	return (
		<Fragment>
			<div className={"lesson-item"}>
				<Grid>
					{/*For computer screen*/}
					<Grid.Row only={"computer"}>
						<Grid.Column className={"thumb-col"} width={3}>
							<Image className={"thumbnail"} src={props.image} size={"tiny"}/>
						</Grid.Column>
						<Grid.Column className={"info-col"} width={9}>
							<div className={"info"}>
								<h4 className={"title"}>{props.name}</h4>
								<p className={"count"}>Số từ: {props.count}</p>
							</div>
						</Grid.Column>
						<Grid.Column className={"btn-col"} width={4}>
							<button className={"practice-button"} onClick={()=>OnPracticeClick()}>Bắt đầu học</button>
						</Grid.Column>
					</Grid.Row>
					{/*For tablet screen*/}
					<Grid.Row only={"tablet"}>
						<Grid.Column className={"thumb-col"} width={4}>
							<Image className={"thumbnail"} src={props.image} />
						</Grid.Column>
						<Grid.Column className={"info-col"} width={7}>
							<div className={"info"}>
								<h4 className={"title"}>{props.name}</h4>
								<p className={"count"}>Số từ: {props.count}</p>
							</div>
						</Grid.Column>
						<Grid.Column className={"btn-col"} width={5}>
							<button className={"practice-button"} onClick={()=>OnPracticeClick()}>Bắt đầu học</button>
						</Grid.Column>
					</Grid.Row>
					{/*For mobile screen*/}
					<Grid.Row only={"mobile"}>
						<Grid.Column className={"thumb-col"} width={7}>
							<Image className={"thumbnail"} size={'tiny'} src={props.image} />
						</Grid.Column>
						<Grid.Column className={"info-col"} width={9}>
							<div className={"info"}>
								<h4 className={"title"}>{props.name}</h4>
								<p className={"count"}>Số từ: {props.count}</p>
							</div>
						</Grid.Column>
						<Grid.Column className={"btn-col"} width={16}>
								<button className={"practice-button"} onClick={()=>OnPracticeClick()} >Bắt đầu học</button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		</Fragment>
	);
}

export default withRouter(LessonItemComponent);