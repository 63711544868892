import React, { Fragment } from 'react'
import SentenceTranslateComponent from './components/SentenceTranslateComponent'

function index(props) {
    return (
        <Fragment>
            <SentenceTranslateComponent />
        </Fragment>
    )
}


export default index


