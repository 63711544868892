import {BACKUP_CLEAR_HISTORY, BACKUP_COMPLETE, BACKUP_REQUEST} from "../../actions/types";


const initialState = {
    isFetching: true
};
export default function (state = initialState, action) {
    switch (action.type) {
        case BACKUP_REQUEST:
            return {
                ...state,
                type: action.type
            };
        case BACKUP_COMPLETE:
            return {
                ...state,
                isFetching: false,
                success: action.success,
                type: action.type
            };
        case BACKUP_CLEAR_HISTORY:
            return {
                state
            };
        default:
            return state
    }
}
