import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './SpeakerComponent.css'
import {ReactComponent as SpeakerIcon} from "../../../assets/icons/speaker.svg";
import md5 from 'md5'

SpeakerComponent.propTypes = {
	wordToPronounce: PropTypes.string.isRequired,
	lang: PropTypes.string.isRequired
};
SpeakerComponent.defaultProps = {
	lang: 'uk'
};

function SpeakerComponent(props) {
	const [word,setWord] = useState("");
	const [lang,setLang] = useState("");
	const [isPlaying,setIsPlaying] = useState(false)

	useEffect(()=>{
		setWord(props.wordToPronounce)
	},[props.wordToPronounce])
	useEffect(()=>{
		setLang(props.lang === "en" ? "uk" : props.lang)
	},[props.lang])

	const OnPronounce = ()=>{
		const hashed = md5(word + '.h')
		const url = "https://api.tudienviet.vn/dict/audio/"+lang+"/" + hashed + ".mp3?w="+word;
		const audio = new Audio(url)
		setIsPlaying(true)
		audio.play().then(()=>{
			setIsPlaying(false)
		});
	}
	return (
		<div className="swapper-speaker-icon" >
			<button className="svg-icon" disabled={isPlaying} onClick={() => OnPronounce()}>
				<SpeakerIcon />
			</button>
		</div>
	);
}

export default SpeakerComponent;