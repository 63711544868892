export function removeWordFromLocalStorageArray(data, arrayKey) {
    let array;
    //is anything in localstorage?
    if (localStorage.getItem(arrayKey) === null) {
        array = [];
    } else {
        // Parse the serialized data back into an array of objects
        array = JSON.parse(localStorage.getItem(arrayKey));
    }
    let newArr = array.filter(word => word.id !== data.id);
    localStorage.setItem(arrayKey, JSON.stringify(newArr))
}
