import {LESSONS_REQUEST, LESSONS_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const LessonsActions = (code) => (dispatch) => {
	dispatch({type: LESSONS_REQUEST});
	API.get('/lesson/lesson?name='+code, (error, res) => {
		if(error){
			dispatch({type: LESSONS_COMPLETE, success: false});
			dispatch(returnErrors(error,true, LESSONS_COMPLETE))
		} else {
			dispatch({type: LESSONS_COMPLETE, success: true,payload:res.data});
		}
	})
};
