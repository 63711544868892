import {CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_COMPLETE} from "../../actions/types";


const initialState = {
};

export default function(state = initialState, action) {
	switch(action.type) {
		case CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				isRequesting: true
			};
		case CHANGE_PASSWORD_COMPLETE:
			return {
				...state,
				isRequesting: false,
				success: action.success,
				msg: action.msg,
				error: action.error,
				type: action.type
			};
		default:
			return state;
	}
}
