import React, {Fragment, useEffect, useRef, useState} from "react";
import {Button} from 'semantic-ui-react'
import Avatar from 'react-avatar';
import {ReactComponent as AngleDownIcon} from "../../../assets/icons/angle-down.svg";
import {ReactComponent as BarsIcon} from "../../../assets/icons/bars-icon.svg";
import "./NavBarComponent.css"
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logoutActions} from "../../../actions/logout/logoutActions";
import logo from "../../../assets/images/logo.png";
const NavBarComponent = (props) => {
	const [isOpenNav, setIsOpenNav] = useState(false);
	const [isOpenUserNav, setIsOpenUserNav] = useState(false);
	const [isMobileMenuOpen, setIsMobileMobileOpen] = useState(false);
	const {user} = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const topNavStyles = { display: "none" };
	if(isOpenNav){ topNavStyles.display = "block"; }
	const userNavStyles = { display: "none" };
	if(isOpenUserNav){ userNavStyles.display = "block"; }

	const navBarRef = useRef(null);
	useOutsideAlerter(navBarRef);
	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsOpenNav(false)
					setIsOpenUserNav(false)
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const onLogout = ()=> {
		dispatch(logoutActions());
	}

	return(
		<section className={"nav-bar"}>
			<ul className={"nav-menu"}>
				<li className={"logo"}>
					<img alt="logo" onClick={()=>props.history.push('/')} src={logo}/>
				</li>
				<li className={`nav-menu-item ${props.location.pathname === "/" ? " active" : ""}`}>
					<a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/')}>Tra từ</a>
				</li>
				<li className={`nav-menu-item ${props.location.pathname === "/sentence-translate" ? " active" : ""}`}>
					<a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/sentence-translate')}>Dịch câu</a>
				</li>
				<li className={`nav-menu-item ${props.location.pathname === "/my-words" ? " active" : ""}`}>
					<a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/my-words')}>Từ của bạn</a>
				</li>
				<li className={`nav-menu-item ${props.location.pathname === "/recent-words" ? " active" : ""}`}>
					<a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/recent-words')}>Từ gần đây</a>
				</li>
				<li className={`nav-menu-item ${props.location.pathname === "/crucial-words" ? " active" : ""}`}>
					<a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/crucial-words')}>Từ quan trọng</a>
				</li>
				<li className={`nav-menu-item ${props.location.pathname === "/lesson/category" ? " active" : ""}`}>
          <a className={"menu-item"} >Lessons</a>
          <div className={"dropdown-content"}>
              <a className={"submenu"} href="#"
                 onClick={()=>props.history.push('/lesson/category/0')}>
                  Gói từ vựng VIP luyện thi</a>
              <a className={"submenu"} href="#"
                 onClick={()=>props.history.push('/lesson/category/1')}>
                  Gói từ vựng VIP SGK</a>
          </div>
				</li>
				{
					user
						?
						<Fragment>
							<div className={"dropdown user"} style={{marginLeft: "35px"}}>
								<Button className={"btn-user"}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Avatar src={user.avatar} size="36" round={true} style={{marginLeft: "-10px",marginRight: "10px"}}/>
										<span>{user.display_name}</span> <AngleDownIcon/>
									</div>
								</Button>
								<div className={"dropdown-content"}>
									<a href={"#"} onClick={()=>props.history.push('/setup')}>Cài đặt tài khoản</a>
									<a href={"#"} onClick={()=>props.history.push('/password/change')}>Đổi mật khẩu</a>
									<a href={"#"} onClick={()=>{onLogout();props.history.push("")}}>Đăng xuất</a>
								</div>
							</div>
						</Fragment>
						:
						<Fragment>
							<Button className={"btn btn-login"}  onClick={()=>props.history.push('/login')}>Đăng nhập</Button>
							<Button className={"btn btn-register"} onClick={()=>props.history.push('/signup')}>Đăng ký</Button>
						</Fragment>
				}

				<div className={"top-nav-expand"} ref={navBarRef}>
					<div className={"icon-group"}>
						{
							user ?
								<div onClick={()=>{setIsOpenUserNav(!isOpenUserNav);setIsOpenNav(false)}}>
								<Avatar src={user.avatar} size="25" round={true} style={{marginLeft: "-10px",marginRight: "10px"}}/>
								</div>
								: ""
						}
						<div className={"icon"} onClick={()=>{setIsOpenNav(!isOpenNav);setIsOpenUserNav(false)}}>
							<BarsIcon/>
						</div>
					</div>

					<div className={"top-nav-content"} style={userNavStyles}>
						<ul>
							<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/setup')}>Cài đặt tài khoản</a></li>
							<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/password/change')}>Đổi mật khẩu</a></li>
							<li><a className={"menu-item"} href={"#"} onClick={()=>{onLogout();props.history.push("")}}>Đăng xuất</a></li>
						</ul>
					</div>
					<div className={"top-nav-content"} style={topNavStyles}>
						<ul>
							{
								!user ?
									<>
									<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/login')}>Đăng nhập</a></li>
									<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/singup')}>Đăng ký</a></li>
									</>
									: ""
							}
							<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/')}>Tra từ</a></li>
							<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/sentence-translate')}>Dịch câu</a></li>
							<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/my-words')}>Từ của bạn</a></li>
							<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/recent-words')}>Từ gần đây</a></li>
							<li><a className={"menu-item"} href={"#"} onClick={()=>props.history.push('/crucial-words')}>Từ quan trọng</a></li>
							<li><a className={"menu-item"} onClick={()=>setIsMobileMobileOpen(!isMobileMenuOpen)}>Lessons<AngleDownIcon/></a>
                 <div className={`dropdown-content ${isMobileMenuOpen ? "" : "close"}`} >
                   <a className={"submenu"} href="#"
                      onClick={()=>props.history.push('/lesson/category/0')}>
                       Gói từ vựng VIP luyện thi</a>
                   <a className={"submenu"} href="#"
                      onClick={()=>props.history.push('/lesson/category/1')}>
                       Gói từ vựng VIP SGK</a>
                 </div>
							</li>
						</ul>
					</div>
				</div>
			</ul>
		</section>
	)
};

export default withRouter(NavBarComponent)
