import React, { useState } from "react";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as VocabularyIcon } from "../../../assets/icons/vocabulary.svg";
import { ReactComponent as SortAZIcon } from "../../../assets/icons/sortAZ.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/arrow-right.svg";
import PropsTypes from 'prop-types'
import "./FilterVocabularyComponent.css";

function FilterVocabularyComponent(props) {
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const [popupChild, setPopupChild] = useState(null);

    const closePopup = () => {
        setPopupChild(null);
        setIsOpenPopup(false)
    }

    const SortAZComponent = () => {
        return (
            <div className={"filter-popup"} style={popupChild === "sortAZ" ? { display: "block" } : {}}>
                <div className={"header popup-child"}>
                    <div className={"back"} onClick={() => setPopupChild(null)}><ArrowRightIcon /></div>
                    <div className={"title"}>Sắp xếp từ A - Z</div>
                </div>
                <div className={"popup-child-content"}>
                    <p>What is Lorem Ipsum</p>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
                <div className={"popup-child-actions"}>
                    <div className={"sortAZ"}>
                        <button onClick={() => { props.onSortAZ(); closePopup() }}>Sắp xếp</button>
                    </div>
                </div>
            </div>
        )
    };

    const SortComponent = () => {
        return (
            <div className={"filter-popup"} style={popupChild === "sort" ? { display: "block" } : {}}>
                <div className={"header popup-child"}>
                    <div className={"back"} onClick={() => setPopupChild(null)}><ArrowRightIcon /></div>
                    <div className={"title"}>Sắp xếp từ gần đây của bạn</div>
                </div>
                <div className={"popup-child-content"}>
                    <p>What is Lorem Ipsum</p>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
                <div className={"popup-child-actions"}>
                    <div className={"sortAZ"}><button onClick={() => { props.onSortRemind(); closePopup() }}>Sắp xếp</button></div>
                </div>
            </div>
        )
    };

    const VocabularyReminderComponent = () => {
        return (
            <div className={"filter-popup"} style={popupChild === "reminder" ? { display: "block" } : {}}>
                <div className={"header popup-child"}>
                    <div className={"back"} onClick={() => setPopupChild(null)}><ArrowRightIcon /></div>
                    <div className={"title"}>Nhắc nhở từ gần đây của tôi</div>
                </div>
                <div className={"popup-child-content"}>
                    <p>What is Lorem Ipsum</p>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
                <div className={"popup-child-actions"}>
                    <div className={"remind"}><button onClick={() => { props.onRemindAll(); closePopup() }}>Nhắc nhở tất cả từ gần đây</button></div>
                    <div className={"delete"}><button onClick={() => { props.onDeleteRemindAll(); closePopup() }}>Xóa nhắc nhở tất cả từ gần đây</button></div>
                </div>
            </div>
        )
    };

    const DeleteAllComponent = () => {
        return (
            <div className={"filter-popup"} style={popupChild === "delete" ? { display: "block" } : {}}>
                <div className={"header popup-child"}>
                    <div className={"back"} onClick={() => setPopupChild(null)}><ArrowRightIcon /></div>
                    <div className={"title"}>Xóa tất cả</div>
                </div>
                <div className={"popup-child-content"}>
                    <p>What is Lorem Ipsum</p>
                    <span>Bạn có muốn xoá tất cả các từ gần đây ?</span>
                </div>
                <div className={"popup-child-actions"}>
                    <div className={"sortAZ"}><button onClick={() => { props.onDelete(); closePopup() }}>OK</button></div>
                </div>
            </div>
        )
    };

    const MainPopupComponent = () => {
        return (
            <div className={"filter-popup"} style={isOpenPopup ? { display: "block" } : {}}>
                <div className={"header"}>
                    <div className={"title"}>Lọc từ của bạn</div>
                    <div className={"close"} onClick={() => setIsOpenPopup(false)}><CloseIcon /></div>
                </div>
                <div className={"list-option"}>
                    <div className={"option-item"} onClick={() => setPopupChild("reminder")}>
                        <div className={"left-side"}>
                            <div className={"icon"}>
                                <VocabularyIcon />
                            </div>
                            <div className={"text"}>
                                Nhắc nhở từ gần đây của tôi
                            </div>
                        </div>
                        <div className={"right-side"}>
                            <div className={"icon"}>
                                <ArrowRightIcon />
                            </div>
                        </div>
                    </div>
                    <div className={"option-item"} onClick={() => setPopupChild("sortAZ")}>
                        <div className={"left-side"}>
                            <div className={"icon"}>
                                <SortAZIcon />
                            </div>
                            <div className={"text"}>
                                Sắp xếp từ A - Z
                            </div>
                        </div>
                        <div className={"right-side"}>
                            <div className={"icon"}>
                                <ArrowRightIcon />
                            </div>
                        </div>
                    </div>
                    <div className={"option-item"} onClick={() => setPopupChild("sort")}>
                        <div className={"left-side"}>
                            <div className={"icon"}>
                                <SortIcon />
                            </div>
                            <div className={"text"}>
                                Sắp xếp từ gần đây của tôi
                            </div>
                        </div>
                        <div className={"right-side"}>
                            <div className={"icon"}>
                                <ArrowRightIcon />
                            </div>
                        </div>
                    </div>
                    <div className={"option-item"} onClick={() => setPopupChild("delete")}>
                        <div className={"left-side"}>
                            <div className={"icon"}>
                                <DeleteIcon />
                            </div>
                            <div className={"text"}>
                                Xóa tất cả
                            </div>
                        </div>
                        <div className={"right-side"}>
                            <div className={"icon"}>
                                <ArrowRightIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const DisplayPopup = () => {
        switch (popupChild) {
            case "sortAZ":
                return <SortAZComponent />
            case "sort":
                return <SortComponent />
            case "reminder":
                return <VocabularyReminderComponent />
            case "delete":
                return <DeleteAllComponent />
            default:
                return <MainPopupComponent />
        }
    }

    return (
        <>
            <div className={"filter-overlay"} style={isOpenPopup ? { display: "block" } : {}} />
            <div className={"filter"} onClick={() => setIsOpenPopup(true)}>
                <FilterIcon />
                <span>Lọc</span>
            </div>
            <DisplayPopup />
        </>
    )
}

FilterVocabularyComponent.propsTypes = {
    onSortAZ: PropsTypes.func.isRequired,
    onSortRemind: PropsTypes.func.isRequired,
    onDelete: PropsTypes.func.isRequired,
    onRemindAll: PropsTypes.func.isRequired,
    onDeleteRemindAll: PropsTypes.func.isRequired
}

export default FilterVocabularyComponent
