import React, {Fragment, useEffect} from 'react';
import {Switch} from "react-router-dom";
import routes from "./routes";
import NavBarComponent from "./pages/home/components/NavBarComponent";
import FooterComponent from "./pages/home/components/FooterComponent";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import {useDispatch, useSelector} from "react-redux";
import {auth} from "./actions/authAction";
import {AUTH_COMPLETE} from "./actions/types";

function App() {
    const dispatch = useDispatch();
    const authenticate = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(auth());
    },[]);
    if(authenticate.type === AUTH_COMPLETE && authenticate.user === null){
        localStorage.removeItem('token');
    }
    if(authenticate.isFetching){
        return <></>
    } else {
        return (
            <Fragment>
                <NavBarComponent/>
                <Switch>
                    {
                        routes.map(({path, component, exact, authenticate, restricted}, key) =>
                            authenticate ?
                                <PrivateRoute exact={exact} path={path} component={component} key={key}/>
                                :
                                <PublicRoute exact={exact} path={path} restricted={restricted} component={component} key={key}/>
                        )
                    }
                </Switch>
                <FooterComponent/>
            </Fragment>

        );
    }

}

export default App;
