import {SIGNUP_REQUEST, SIGNUP_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const signup = (email, password1, password2, displayName) => (dispatch) => {
	dispatch({type: SIGNUP_REQUEST});
	let body = new FormData();
	body.append("email", email);
	body.append("password1",  password1);
	body.append("password2",  password2);
	body.append("display_name",  displayName);
	API.post('/rest-auth/registration/', body, (error, res) => {
		if(error){
			dispatch({type: SIGNUP_COMPLETE, success: false});
			let errorMsg = [];
			for (let key in error) {
				if (Object.prototype.hasOwnProperty.call(error, key)) {let vals = error[key];
					vals.forEach(err=>errorMsg.push(err))
				}
			}
			dispatch(returnErrors(errorMsg,true, SIGNUP_COMPLETE))
		} else {
			dispatch({type: SIGNUP_COMPLETE, success: true, payload: res});
			localStorage.setItem('token',res.key);
		}
	})
};