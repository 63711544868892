import React from 'react';
import md5 from "md5";
import {Image} from "semantic-ui-react";
import SpeakerComponent from "./SpeakerComponent";
import StarBookmarkComponent from "../../../common-components/star-bookmark/StarBookmarkComponent";
import './WordItemComponent.css'

WordItemComponent.propTypes = {

};

function WordItemComponent({item,code,onClick}) {
	const imgURL = `http://cdn.tudienviet.vn/lesson/${code}/images/${md5("img" + item.image + "Tflat2015")}.jpg`
	const audioURL = `http://cdn.tudienviet.vn/lesson/${code}/audio/${md5("audio" + item.audio + "Tflat2015")}.mp3`
	return (
		<>
			<div className={"word-content-item"} >
				<div className={"thumb-wrapper"} onClick={()=>onClick(item.word)}>
					<Image className={"thumbnail"} src={imgURL}/>
				</div>
				<div className={"info"} onClick={()=>onClick(item.word)}>
					<p className={"word"}>{item.word}</p>
					<p className={"pronun"}>{item.pronunciation}&emsp;&emsp;{item.type}</p>
					<p className={"mean"}>{item.mean}</p>
				</div>
				<div className={"action"}>
					<SpeakerComponent audio={audioURL}/>
					<div className={"star"}>
						<StarBookmarkComponent word={item}/>
					</div>
				</div>
			</div>
		</>
	);
}

export default WordItemComponent;