import React, {useState, useEffect, useRef} from "react";
import {ReactComponent as BookIcon} from "../../assets/icons/book.svg";
import {ReactComponent as CloudDownloadIcon} from "../../assets/icons/clould-download.svg";
import GooglePlayBadge  from "../../assets/images/google-play-badge.png";
import AppStoreBadge from "../../assets/images/app-store-badge.svg";
import { useSwipeable } from "react-swipeable";
import "./styles.css"
import SearchComponent from "./components/SearchComponent";
import VdictDictionaryComponent from "./components/VdictDictionaryComponent";

function HomePage() {
	const [currentIntroduction, setCurrentIntroduction] = useState(1);

	const handlers = useSwipeable({
		onSwipedLeft: () => setCurrentIntroduction(2),
		onSwipedRight: () => setCurrentIntroduction(1),
		preventDefaultTouchmoveEvent: true,
		trackMouse: true
	});

	return(
		<section className={"home-container"}>
			<VdictDictionaryComponent/>
			<p className={"description"}>Tra từ khóa bạn cần tìm ...</p>
			<SearchComponent/>
			<div className={"introduction"} {...handlers}>
				<div className={"intro-item fade"} style={currentIntroduction === 1? {display: "block"} : {}}>
					<div className={"title"}>
						<div className={"icon"}>
							<BookIcon/>
						</div>
						<h2>Vdict Dictionary</h2>
					</div>
					<ul>
						<li>Từ điển trực tuyến miễn phí cho người Việt</li>
						<li>Cung cấp 2 bộ từ điển chính: Anh – Việt, Việt – Anh</li>
						<li><span>Kho từ đồ sộ cùng hệ thống gợi ý từ thông minh, Vdict Dictionary giúp tra cứu nhanh chóng nhất.</span></li>
					</ul>
				</div>
				<div className={"intro-item fade"} style={currentIntroduction === 2? {display: "block"} : {}}>
					<div className={"title"}>
						<div className={"icon"}>
							<CloudDownloadIcon/>
						</div>
						<h2>Vdict Dictionary Application</h2>
					</div>
					<ul>
						<li>Tra từ offline, không cần kết nối internet.</li>
						<li>Hỗ trợ tra từ bằng giọng nói.</li>
						<li>
							"Cửa sổ tra nhanh" cho phép tra từ trong ứng dụng khác
							<div className={"download-app"}>
								<a href={"https://apps.apple.com/vn/app/t%E1%BB%AB-%C4%91i%E1%BB%83n-anh-vi%E1%BB%87t-vdict/id1141674224"} target={"_blank"}>
									<img alt={"app-store-download"} src={AppStoreBadge}/>
								</a>
								<a href={"https://play.google.com/store/apps/details?id=com.hotuy.vdict"} target={"_blank"}>
									<img alt={"app-store-download"} src={GooglePlayBadge}/>
								</a>
							</div>
						</li>
					</ul>
				</div>
				<div className={"switch-introduction"}>
					<span className={currentIntroduction === 1? "dot active": "dot"} onClick={()=>setCurrentIntroduction(1)}/>
					<span className={currentIntroduction === 2? "dot active": "dot"} onClick={()=>setCurrentIntroduction(2)}/>
				</div>
			</div>
		</section>
	)
}
export default HomePage
