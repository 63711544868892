import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Image} from "semantic-ui-react";
import md5 from 'md5'
import './CategoryItemComponent.css'
import {withRouter} from 'react-router-dom'

CategoryItemComponent.propTypes = {
	
};

function CategoryItemComponent(props) {

	const OnPracticeClick = ()=>{
		props.history.push('/lesson/lesson/'+props.code+'/'+props.name,{image:props.image})
	}
	return (
		<div className={"category-item"}>
			<Grid>
				{/*For computer screen*/}
				<Grid.Row only={"computer"}>
					<Grid.Column className={"thumb-col"} width={4}>
						<Image className={"thumbnail"} src={props.image} />
					</Grid.Column>
					<Grid.Column className={"info-col"} width={8}>
						<div className={"info"}>
							<h4 className={"title"}>{props.name}</h4>
							<p className={"description"}>{props.description}</p>
						</div>
					</Grid.Column>
					<Grid.Column className={"btn-col"} width={4}>
						<button className={"practice-button"} onClick={()=>OnPracticeClick()}>Luyện tập</button>
					</Grid.Column>
				</Grid.Row>
				{/*For tablet screen*/}
				<Grid.Row only={"tablet"}>
					<Grid.Column className={"thumb-col"} width={4}>
						<Image className={"thumbnail"} src={props.image} />
					</Grid.Column>
					<Grid.Column className={"info-col"} width={8}>
						<div className={"info"}>
							<h4 className={"title"}>{props.name}</h4>
							<p className={"description"}>{props.description}</p>
						</div>
					</Grid.Column>
					<Grid.Column className={"btn-col"} width={4}>
						<button className={"practice-button"} onClick={()=>OnPracticeClick()}>Luyện tập</button>
					</Grid.Column>
				</Grid.Row>
				{/*For mobile screen*/}
				<Grid.Row only={"mobile"}>
					<Grid.Column className={"thumb-col"} width={7}>
						<Image className={"thumbnail"} src={props.image} />
					</Grid.Column>
					<Grid.Column className={"info-col"} width={9}>
						<div className={"info"}>
							<h4 className={"title"}>{props.name}</h4>
						</div>
					</Grid.Column>
					<Grid.Column className={"info-col"} width={16}>
						<div className={"info"}>
							<p className={"description"}>{props.description}</p>
						</div>
					</Grid.Column>
					<Grid.Column className={"btn-col"} width={16}>
						<button className={"practice-button"} onClick={()=>OnPracticeClick()} >Luyện tập</button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
}

export default withRouter(CategoryItemComponent);