import {IMPORTANT_WORD_REQUEST, IMPORTANT_WORD_COMPLETE,CLEAR_IMPORTANT_WORD} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const importantWordActions = ( name,offset= 0,itemPerPage=100) => (dispatch) => {
	dispatch({type: IMPORTANT_WORD_REQUEST});
	API.get( `/lesson/important-word?name=${name}${offset === 0 ? "" : "&offset=" + offset}`
		,  (error, res) => {
		if (error || res === -1){
			dispatch({type: IMPORTANT_WORD_COMPLETE, success: false});
			dispatch(returnErrors(error ? error : "Something wrong!" ,true, IMPORTANT_WORD_COMPLETE))
		} else {
			dispatch({type: IMPORTANT_WORD_COMPLETE, success: true, payload: res.results,count:res.count,page:(offset/itemPerPage)+1});
		}
	})
};

export const clearImpotantWord = ()=>(dispatch)=>{
	dispatch({type: CLEAR_IMPORTANT_WORD});
}
