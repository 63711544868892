import {TRANSLATE_REQUEST, TRANSLATE_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const translateActions = (text, lang = "en_vi") => (dispatch) => {
	dispatch({type: TRANSLATE_REQUEST});
	API.get('/dict/translate'+"?text="+text+"&lang="+lang,(error, res) => {
		if(error){
			dispatch({type: TRANSLATE_COMPLETE, success: false});
			dispatch(returnErrors(error,true, TRANSLATE_COMPLETE))
		} else {
			dispatch({type: TRANSLATE_COMPLETE, success: true,payload:res});
		}
	})
};