import React from "react";
import PropTypes from 'prop-types';

const Theme = (props) => {
    return(
        <div data-theme={props.name}>
            {props.children}
        </div>
    )
};

Theme.defaultProps = {
    name: "royal-blue"
};

Theme.propsTypes = {
    name: PropTypes.string
};




export default Theme
