import {CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const changePasswordAction = (new_password1,new_password2) => (dispatch) => {
	dispatch({type: CHANGE_PASSWORD_REQUEST});
	let body = new FormData();
	body.append("new_password1", new_password1);
	body.append("new_password2", new_password2);
	API.post('/rest-auth/password/change/', body, (error, res) => {
		console.log(error)
		if(error){
			dispatch({type: CHANGE_PASSWORD_COMPLETE, success: false});
			if(error.length){
				let errorMsg = [];
				for (let key in error) {
					if (Object.prototype.hasOwnProperty.call(error, key)) {let vals = error[key];
						vals.forEach(err=>errorMsg.push(err))
					}
				}
				dispatch(returnErrors(errorMsg,true, CHANGE_PASSWORD_COMPLETE))
			} else {
				dispatch(returnErrors([error.detail],true, CHANGE_PASSWORD_COMPLETE))
			}

		} else {
			dispatch({type: CHANGE_PASSWORD_COMPLETE, success: true, msg: res.detail});
		}
	})
};
