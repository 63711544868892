import {LESSON_CATEGORY_REQUEST, LESSON_CATEGORY_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const LessonCategoryActions = (id) => (dispatch) => {
	dispatch({type: LESSON_CATEGORY_REQUEST});
	API.get('/lesson/category?cat_id='+id, (error, res) => {
		if(error){
			dispatch({type: LESSON_CATEGORY_COMPLETE, success: false});
			dispatch(returnErrors(error,true, LESSON_CATEGORY_COMPLETE))
		} else {
			dispatch({type: LESSON_CATEGORY_COMPLETE, success: true,payload:res.data});
		}
	})
};
