import {
    LOGIN_COMPLETE,
    LOGIN_FACEBOOK_COMPLETE,
    LOGIN_FACEBOOK_REQUEST, LOGIN_GOOGLE_COMPLETE,
    LOGIN_GOOGLE_REQUEST,
    LOGIN_REQUEST
} from "../../actions/types";


const initialState = {
};

export default function(state = initialState, action) {
    switch(action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true
            };
        case LOGIN_COMPLETE:
            return {
                ...state,
                isLoggingIn: false,
                success: action.success
            };
        case LOGIN_FACEBOOK_REQUEST:
            return {
                ...state,
                type: action.type
            };
        case LOGIN_FACEBOOK_COMPLETE:
            return {
                ...state,
                success: action.success,
                type: action.type,
                isFirstTime: action.isFirstTime
            };
        case LOGIN_GOOGLE_REQUEST:
            return {
                ...state,
                type: action.type
            };
        case LOGIN_GOOGLE_COMPLETE:
            return {
                ...state,
                success: action.success,
                type: action.type,
                isFirstTime: action.isFirstTime
            };
        default:
            return state;
    }
}
