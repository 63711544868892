import React, {useEffect, useState} from "react";
import classNames from 'classnames'
import { ReactComponent as SpeakerIcon } from "../../../assets/icons/speaker.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg"
import {saveWordToLocalStorageArray} from "../../../utils/saveWordToLocalStorageArray";
import {removeWordFromLocalStorageArray} from "../../../utils/removeWordFromLocalStorageArray";
import * as  md5 from 'md5';

function ImportantItemVocabularyComponent({ vocabulary, searchType=0, onClick }) {
	const [isBookmarked, setIsBookmarked] = useState(false);
	const [avBookmark, setAvBookmark] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);
	useEffect(() => {
			setIsBookmarked(avBookmark.some(item => item.word.toLowerCase() === vocabulary.word.toLowerCase()))
	},[]);

	useEffect(() => {
			setAvBookmark(JSON.parse(localStorage.getItem('av_bookmark')) || [])
	}, [isBookmarked])

	const onBookmark = () => {
			let index = avBookmark.findIndex(word => word.word.toLowerCase() === vocabulary.word.toLowerCase());
			if(index === -1){
				saveWordToLocalStorageArray(vocabulary, 'av_bookmark');
				setIsBookmarked(true);
			} else {
				setIsBookmarked(false);
				removeWordFromLocalStorageArray(vocabulary, 'av_bookmark');
			}
	};
	const onClickSpeaker = async () => {
		let audio = new Audio("https://api.tudienviet.vn/dict/audio/uk/" + md5(vocabulary.word + '.h') + ".mp3?w=" + vocabulary.word);
		await audio.play();
	};

	return (
		<div className={"content-item"}>
			<div onClick={() => {
				onClick();
			}}>
				<p>{vocabulary.word}</p>
				<p>{vocabulary.contents}</p>
			</div>
			<div className={"speaker"}>
				<SpeakerIcon onClick={() => onClickSpeaker()} />
			</div>
			<div className={classNames("mark", { "disable": !isBookmarked})}>
				<StarIcon onClick={() => onBookmark()} />
			</div>
		</div>
	)
}

ImportantItemVocabularyComponent.propTypes = {

};


export default ImportantItemVocabularyComponent
