import {IMPORTANT_WORD_REQUEST, IMPORTANT_WORD_COMPLETE, CLEAR_IMPORTANT_WORD} from "../../actions/types";


const initialState = {
	payload: [],
	count: 0,
	page:0
};

export default function(state = initialState, action) {
	switch(action.type) {
		case IMPORTANT_WORD_REQUEST:
			return {
				...state,
				isRequesting: true
			};
		case IMPORTANT_WORD_COMPLETE:
			return {
				...state,
				isRequesting: false,
				success: action.success,
				payload: action.payload,
				count: action.count,
				error: action.error,
				page: action.page
			};
		case CLEAR_IMPORTANT_WORD:
			return {
				payload: {results:[]},
			};
		default:
			return state;
	}
}
