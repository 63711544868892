export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const FETCHING_DATA = "FETCHING_DATA";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
export const LOGIN_FACEBOOK_REQUEST = "LOGIN_FACEBOOK_REQUEST";
export const LOGIN_FACEBOOK_COMPLETE = "LOGIN_FACEBOOK_COMPLETE";
export const LOGIN_GOOGLE_REQUEST = "LOGIN_GOOGLE_REQUEST";
export const LOGIN_GOOGLE_COMPLETE = "LOGIN_GOOGLE_COMPLETE";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_COMPLETE = "AUTH_COMPLETE";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_COMPLETE = "SIGNUP_COMPLETE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_COMPLETE = "RESET_PASSWORD_COMPLETE";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_COMPLETE = "CHANGE_PASSWORD_COMPLETE";
export const TRANSLATE_REQUEST = "TRANSLATE_REQUEST";
export const TRANSLATE_COMPLETE= "TRANSLATE_COMPLETE";
export const SEARCH_WORD_REQUEST = "SEARCH_WORD_REQUEST";
export const SEARCH_WORD_COMPLETE = "SEARCH_WORD_COMPLETE";
export const SEARCH_WORD_DETAIL_REQUEST = "SEARCH_WORD_DETAIL_REQUEST";
export const SEARCH_WORD_DETAIL_COMPLETE = "SEARCH_WORD_DETAIL_COMPLETE";
export const IMPORTANT_WORD_REQUEST = "IMPORTANT_WORD_REQUEST";
export const IMPORTANT_WORD_COMPLETE = "IMPORTANT_WORD_COMPLETE";
export const CLEAR_IMPORTANT_WORD = "CLEAR_IMPORTANT_WORD";
export const LESSON_CATEGORY_REQUEST = "LESSON_CATEGORY_REQUEST";
export const LESSON_CATEGORY_COMPLETE = "LESSON_CATEGORY_COMPLETE";
export const LESSONS_REQUEST = "LESSONS_REQUEST";
export const LESSONS_COMPLETE = "LESSONS_COMPLETE";
export const LESSON_WORD_REQUEST = "LESSON_WORD_REQUEST";
export const LESSON_WORD_COMPLETE = "LESSON_WORD_COMPLETE";
export const BACKUP_REQUEST = "BACKUP_REQUEST";
export const BACKUP_COMPLETE = "BACKUP_COMPLETE";
export const BACKUP_CLEAR_HISTORY = "BACKUP_CLEAR_HISTORY";
export const RESTORE_REQUEST = "RESTORE_REQUEST";
export const RESTORE_COMPLETE = "RESTORE_COMPLETE";
