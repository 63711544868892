import React from 'react'
import { Container } from 'semantic-ui-react'
import propsTypes from "prop-types"
import { ReactComponent as BackIcon } from '../../assets/icons/back-arrow.svg'
import './styles.css'
import { withRouter } from "react-router-dom"

function VersionPage(props) {
    console.log(props);

    const onBackClick = () => {
        props.history.goBack()
    };

    return (
        <Container className="version">
            <h2>Vdict Dictionary</h2>
            <p>Cài đặt tài khoản</p>
            <Container className="content-container">
                <div className="title"><p>VDict Version</p></div>
                <p>Vdict phiên bản {props.version}<br />Phát triển bởi {props.company}</p>
                <button className="svg-icon" onClick={() => onBackClick()}>
                    <BackIcon />
                </button>
            </Container>
        </Container>
    )
}

VersionPage.defaultProps = {
    version: "1.0",
    company: "Vdict Company"
}

VersionPage.propsTypes = {
    version: propsTypes.string,
    company: propsTypes.string
}

export default withRouter(VersionPage)


