import {
    LOGIN_COMPLETE,
    LOGIN_FACEBOOK_COMPLETE,
    LOGIN_FACEBOOK_REQUEST, LOGIN_GOOGLE_COMPLETE,
    LOGIN_GOOGLE_REQUEST,
    LOGIN_REQUEST
} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";
import axios from "axios";

export const login = (email, password) => (dispatch) => {
    dispatch({type: LOGIN_REQUEST});
    let body = new FormData();
    body.append("email", email);
    body.append("password",  password);
    API.post('/rest-auth/login2/', body, (error, res) => {
        if(error){
            dispatch({type: LOGIN_COMPLETE, success: false});
            dispatch(returnErrors(error,true, LOGIN_COMPLETE))
        } else {
            dispatch({type: LOGIN_COMPLETE, success: true});
            localStorage.setItem("token", res.key);
        }
    })
};

export const loginBackend = (data, backend) => dispatch => {
    let typeRequest;
    let typeComplete;
    switch (backend) {
        case "facebook":
            typeRequest = LOGIN_FACEBOOK_REQUEST
            typeComplete = LOGIN_FACEBOOK_COMPLETE
            break;
        case "google":
            typeRequest = LOGIN_GOOGLE_REQUEST
            typeComplete = LOGIN_GOOGLE_COMPLETE
            break;
    }
    let body = new FormData();
    body.append("access_token", data.access_token);
    API.post('/rest-auth/' + backend + '/', body, (error, res) => {
        if(error){
            console.log(1)
            dispatch({type: typeComplete, success: false});
            dispatch(returnErrors("Lỗi",true, typeComplete))
        } else {
            console.log('2')
            dispatch({type: typeComplete, success: true, isFirstTime: res['is_first_time']});
            localStorage.setItem("token", res.key);
        }
    })
    // const tokenBlob = new Blob([
    //     JSON.stringify(data, null, 2)
    // ], {type : 'application/json'});
    // const options = {
    //     method: 'POST',
    //     body: tokenBlob,
    // };
    // fetch('https://api.tudienviet.vn/rest-auth/' + backend + '/', options).then(r => {
    //     r.json().then(user => {
    //         if (user.access_token) {
    //             dispatch({type: typeRequest, success: true, isFirstTime: user['is_first_time']});
    //             localStorage.setItem("token", user.access_token);
    //         } else {
    //             dispatch({type: typeComplete, success: false});
    //             dispatch(returnErrors("Lỗi",true, typeComplete))
    //         }
    //     });
    // })
};
