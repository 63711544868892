import API from "../utils/API";
import {AUTH_COMPLETE} from "./types";

export const auth = () => (dispatch) => {
    API.getByAuth("/rest-auth/user", (err, data) => {
        if(err){
            dispatch({type: AUTH_COMPLETE, user: null});
        } else {
            dispatch({type: AUTH_COMPLETE, user: data});
        }
    })
};
