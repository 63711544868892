import {LESSON_WORD_REQUEST, LESSON_WORD_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const LessonWordActions = (name,id) => (dispatch) => {
	dispatch({type: LESSON_WORD_REQUEST});
	API.get(`/lesson/word?name=${name}&id=${id}`, (error, res) => {
		if(error){
			dispatch({type: LESSON_WORD_COMPLETE, success: false});
			dispatch(returnErrors(error,true, LESSON_WORD_COMPLETE))
		} else {
			dispatch({type: LESSON_WORD_COMPLETE, success: true,payload:res.data});
		}
	})
};
