import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import SignUpPage from "./pages/signup";
import SentenceTranslatePage from "./pages/sentense-translate";
import MyVocabularyComponent from "./pages/my-words";
import VersionPage from "./pages/version";
import RecentVocabularyComponent from "./pages/recent-words";
import CrucialWordsComponent from "./pages/crucial-words";
import SetupPage from "./pages/setup";
import PolicyPage from "./pages/policy";
import ResetPasswordPage from "./pages/reset-password";
import ChangePasswordPage from "./pages/change-password";
import LessonCategoryPage from "./pages/lesson-category";
import LessonsPage from "./pages/lesson-lessons";
import LessonWordPage from "./pages/lesson-word";
import BackUpComponent from "./pages/setup/components/BackUpComponent";
import RestorePage from "./pages/restore";

const routes = [
    {
        path: "/",
        component: HomePage,
        exact: true
    },
    {
        path: "/login",
        component: LoginPage,
        exact: true,
        restricted: true
    },
    {
        path: "/signup",
        component: SignUpPage,
        exact: true,
        restricted: true
    },
    {
        path: "/sentence-translate",
        component: SentenceTranslatePage,
        exact: true
    },
    {
        path: "/version",
        component: VersionPage,
        exact: true
    },
    {
        path: "/my-words",
        component: MyVocabularyComponent,
        exact: true,
        authenticate: true
    },
    {
        path: "/recent-words",
        component: RecentVocabularyComponent,
        exact: true
    },
    {
        path: "/crucial-words",
        component: CrucialWordsComponent,
        exact: true
    },
    {
        path: "/setup",
        component: SetupPage,
        exact: true
    },
    {
        path: "/setup/backup",
        component: BackUpComponent,
        exact: true
    },
    {
        path: "/setup/restore",
        component: RestorePage,
        exact: true
    },
    {
        path: "/private-policy",
        component: PolicyPage,
        exact: true
    },
    {
        path: "/password/reset",
        component: ResetPasswordPage,
        exact: true
    },
    {
        path: "/password/change",
        component: ChangePasswordPage,
        exact: true
    },
    {
        path: "/lesson/category/:id",
        component: LessonCategoryPage,
        exact: true
    },
    {
        path: "/lesson/lesson/:code/:name",
        component: LessonsPage,
        exact: true
    },
    {
        path: "/lesson/word/:code/:id/:name",
        component: LessonWordPage,
        exact: true
    },
    {
        path: "*",
        component: HomePage,
        exact: true
    }
];

export default routes
