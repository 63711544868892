import {BACKUP_REQUEST, BACKUP_COMPLETE} from "../types";
import API from "../../utils/API";

export const backupActions = (data) => (dispatch) => {
	dispatch({type: BACKUP_REQUEST});
	let body = new FormData();
	body.append("data", JSON.stringify(data));
	API.postByAuth('/dict/backup', body, (error, res) => {
		if(res.data.error === -1){
			dispatch({type: BACKUP_COMPLETE, success: false});
		} else {
			dispatch({type: BACKUP_COMPLETE, success: true});
		}
	})
};
