import React, {useEffect, useRef, useState} from "react";
import "./SearchResultItemComponent.css"
import {ReactComponent as CloseIcon} from "../../../assets/icons/close.svg";
import SearchResultComponent from "./SearchResultComponent";
import API from "../../../utils/API";

function SearchResultItemComponent(props) {
    const [popup, setPopup] = useState({isOpen: false});
    useEffect(() => {
        let result = document.querySelector('.p10.search-result-item');
        let aTags = result.getElementsByTagName('a');
        for(let i = 0; i < aTags.length; i++){
            aTags[i].addEventListener("click", f);
        }
        return () => {
            for(let i = 0; i < aTags.length; i++){
                aTags[i].removeEventListener("click", f);
            }
        }
    });
    function f(e) {
        e.preventDefault();
        let offsetTop = (popup.offsetTop ? popup.offsetTop : 0) + e.currentTarget.offsetTop;
        if(popup.isOpen){
            offsetTop = popup.offsetTop
        }
        let type = 0;
        let word = e.currentTarget.getAttribute('href');
        setPopup({isFetching: true, isOpen: false});
        API.get(`/dict/search?type=${type}&q=${word}`,(error, res) => {
            if(error){
            } else {
                if(res.data.some(item => item.word === word)){
                    API.get(`/dict/detail?type=${type}&id=${res.data[0].id}`, (error, res) => {
                        if(!error){
                            let data = {isFetching: false, isOpen: true, ...res.data, offsetTop: offsetTop};
                            setPopup(data);
                        }
                    })
                } else {
                    setPopup({
                        word: word,
                        detail: `<p>Chưa có dữ liệu về từ <b>${word}</b></p>`,
                        isFetching: false,
                        isOpen: true,
                        offsetTop: offsetTop
                    })
                }
            }
        })
    }

    const popupRef = useRef(null);
    useOutsideAlerter(popupRef);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setPopup({isOpen: false});
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const PopupSearchResult = () => {
            const styles = {
                position: "absolute",
                boxShadow: "0 10px 20px 0 rgba(62, 77, 82, 0.1)",
                top: popup.offsetTop + "px"
            };
            return(
                popup.isOpen ?
                <div className={"popup-word"} style={styles} ref={popupRef}>
                    <div  className={"header-popup"}>
                        <div className={"title-popup"}>{popup.word}</div>
                        <div className={"close-popup"} onClick={()=>{
                            setPopup({isOpen: false});
                        }}>
                            <CloseIcon/>
                        </div>
                    </div>
                    <div className={"body-popup"}>
                        {
                            popup.isFetching ? <div style={{padding: "25px", background: "#fff"}}>Loading...</div> : <SearchResultComponent searchType={props.searchType} data={popup} isDisplay={true}/>
                        }
                    </div>
                </div>
                    : null
            )
    };
    return(
        <>
            <PopupSearchResult/>
            <section id={"search-result-item"}>
            <div className='p10 search-result-item'>
                <div id='partofspeech_0'
                     dangerouslySetInnerHTML={{
                         __html: props.content
                     }}>
                </div>
            </div>
            </section>
        </>
    )
}
SearchResultItemComponent.defaultProps = {
};
export default SearchResultItemComponent
