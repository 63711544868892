import React, {useEffect, useState} from "react";
import "./ReviewComponent.css";
import { ReactComponent as BackArrowIcon } from "../../../assets/icons/back-arrow.svg";
import SpeakerComponent from "./../../../common-components/speaker/SpeakerComponent";
import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/next.svg";
import md5 from "md5";

function ReviewComponent(props) {
	const [list, setList] = useState(props.listData);
	const [answer, setAnswer] = useState({});
	const [current, setCurrent] = useState(1);
	const [isReview,setIsReview] = useState(false)
	const [timer,setTimer] = useState(0)
	let audioURL = ""
	useEffect(()=>{
		setList(list)
		setAnswer(props.listData[0])
	},[props.listData])

	if(answer.word)
	{
		audioURL = "https://api.tudienviet.vn/dict/audio/uk/" + md5(answer.word + '.h') + ".mp3?w=" + answer.word
	}

	const goOtherWord = (type) => {
		list.forEach((v, index) => {
			const newIndex = type === "next" ? index + 1 : index - 1;
			if (v.word === answer.word) {
				if (list[newIndex] !== undefined) {
					setAnswer(list[newIndex])
					setCurrent(newIndex + 1)
				} else{
					setAnswer(list[0])
					setCurrent(1)
				}
			}
		});
	}

	useEffect(()=>{
		if(isReview){
			setTimer(setTimeout(()=>{
				goOtherWord("next")
			},3000))
		}
	},[answer])

	const onPlayPause = ()=>{
		setIsReview(!isReview)
		if(!isReview){
			setTimer(setTimeout(()=>{
				goOtherWord("next")
			},3000))
		} else {
			clearTimeout(timer)
			setTimer(0)
		}
	}
	return (
		<section id={"review"}>
			<div className={"review-container"}>
				<div className={"header"}>
					<div className={"back"} onClick={() => props.onGoBack()}>
						<BackArrowIcon />
					</div>
					<div className={"title"}>
						Kiểm tra nhanh
					</div>
					<div className={"order"}>
						<span>{current}</span>/{list.length}
					</div>
				</div>
				<div className={"content"}>
					<div className={"content-item"}>
						<div>
							<p>{answer.word}</p>
							<ul>
								<li>{answer.contents}</li>
							</ul>
						</div>
						<SpeakerComponent play={true} audio={audioURL} />
						<div className={"mark"}>
							<StarIcon />
						</div>
					</div>
				</div>
			</div>
			<div className={"review-action"}>
				<div className={"previous"}>
					<button className={isReview ? "disabled" : ""}  disabled={isReview} onClick={() => goOtherWord("previous")}>
						<NextIcon />
					</button>
				</div>
				<div className={"start"}>
					<button onClick={() => onPlayPause()}>
						{
							isReview
								? <div style={{
									margin: 'auto',
									width: '16px',
									height: '20px',
									borderRight: '5px solid #FFFFFF',
									borderLeft: '5px solid #FFFFFF',
								}} />
								: <div style={{
									margin: 'auto',
									borderTop: "10px solid transparent",
									borderBottom: "10px solid transparent",
									borderLeft: "17px solid #FFFFFF"
								}} />
						}
					</button>
					<p>{isReview ? "Dừng lại" : "Bắt đầu"}</p>
				</div>
				<div className={"next"}>
					<button className={isReview ? "disabled" : ""} disabled={isReview} onClick={() => goOtherWord("next")} >
						<NextIcon />
					</button>
				</div>
			</div>
		</section>
	)
}

export default ReviewComponent
