import React, { useEffect, useState} from 'react';
import './styles.css'
import {saveWordToLocalStorageArray} from "../../utils/saveWordToLocalStorageArray";
import {ReactComponent as BackIcon} from "../../assets/icons/back-arrow.svg";
import {Confirm, Container, Loader,Message} from "semantic-ui-react";
import {clearErrors} from "../../actions/errorAction";
import {restoreActions} from "../../actions/restore/restoreActions";
import moment from 'moment'
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from 'react-router-dom'

function RestorePage(props) {
	const [confirm,setConfirm] = useState(false)
	const [data,setData] = useState({})
	const [isRestored,setIsRestored] = useState(false)
	const dateFormat = 'DD/MM/YYYY'
	const timeFormat = 'hh:mm'
	const restore = useSelector(state=>state.restore)
	const dispatch = useDispatch()

	useEffect(()=>{
		dispatch(clearErrors())
		dispatch(restoreActions())
	},[])

	const getDateTime = (time)=>{
		return `ngày ${moment(time).format(dateFormat)} lúc ${moment(time).format(timeFormat)}`
	}

	const handleConfirm = ()=>{
		const jsonData = JSON.parse(data['data'])
		for(let key in jsonData) {
			localStorage.setItem(key, JSON.stringify(jsonData[key]) )
		}
		setConfirm(false)
		setIsRestored(true)
	}

	const onRestore = ()=>{
		setConfirm(true)
	}

	const onBackClick = ()=>{
		props.history.goBack()
	}
	return (
		<Container className="restore">
			<h2>Vdict Dictionary</h2>
			<p>Cài đặt tài khoản</p>
			{
				isRestored ?
					<div className={'success-action'}>
						<Message success>Khôi phục thành công</Message>
						<button className="go-home" onClick={()=>props.history.push('/')}>Trang chủ </button>
					</div>

					:
					<Container className="content-container">
						<div className="title"><p>Chọn phiên bản khôi phục</p></div>
						<button className="svg-icon" onClick={() => onBackClick()}>
							<BackIcon />
						</button>
						<div className={"versions"}>
							{
								restore.isRequesting
									? <Loader inline/>
									: restore.success && restore.payload.length
									? restore.payload.map((item)=>
										<div className={'restore-item'} onClick={()=>{setData(item);onRestore()}}>
											<h4>Phiên bản {getDateTime(item["created_at"])}</h4>
											<div className={'description'}>
												<div className={'word'}>Từ gần đây:&nbsp;<span>{item["num_recent_word"]}</span></div>
												<div className={'word'}>Từ của bạn:&nbsp;<span>{item["num_your_word"]}</span></div>
												<div className={'word'}>Ghi chú:&nbsp;<span>{item["num_note"]}</span></div>
											</div>
										</div>)
									: <Message color={"yellow"}>Danh sách trống</Message>
							}
						</div>
					</Container>
			}

			<Confirm
				open={confirm}
				header={`Bạn có chắc chắn muốn khôi phục phiên bản ${getDateTime(data['created_at'])}?`}
				content={
					<div className={'description confirm'}>
						<div className={'word'}>Từ gần đây:&nbsp;<span>{data['num_recent_word']}</span></div>
						<div className={'word'}>Từ của bạn:&nbsp;<span>{data['num_your_word']}</span></div>
						<div className={'word'}>Ghi chú:&nbsp;<span>{data['num_note']}</span></div>
					</div>}
				cancelButton='Huỷ'
				confirmButton="Khôi phục"
				onCancel={()=>setConfirm(false)}
				onConfirm={()=>handleConfirm()}
			/>
		</Container>
	);
}

export default withRouter(RestorePage);