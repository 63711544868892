import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Loader} from 'semantic-ui-react';
import Validator from 'validator'
import SampleMessage from './messages/SampleMessage'
import ErrorMessage from './messages/ErrorMessage'
import {withRouter} from 'react-router-dom'
import { Message } from 'semantic-ui-react';
import {clearErrors} from "../../actions/errorAction";
import {resetPasswordAction} from "../../actions/reset-password/resetPasswordActions"
import {useSelector, useDispatch} from "react-redux";
import {RESET_PASSWORD_COMPLETE} from "../../actions/types";
import './styles.css'

function ResetPasswordPage(props) {
	const [email, setEmail] = useState('')
	const [error, setError] = useState({})
	const dispatch = useDispatch();
	const haveEmailError = () => { return !Validator.isEmail(email)  };
	const emailInputClass = error.email ? "error" : "";
	const errorMsg = useSelector(state => state.error);
	const resetPassword = useSelector(state=>state.resetPassword);

	const onEmailChange = e => { setEmail(e.target.value); setError({}) }

	const isEmpty = (o) => {
		for (var i in o) {
			if (o.hasOwnProperty(i)) return false;
		}
		return true;
	}


	const onSubmit = () => {
		const newError = validate()
		if (isEmpty(newError)){
			dispatch(clearErrors());
			dispatch(resetPasswordAction(email));
		}
		else
			setError(newError)
	}

	const validate = () => {
		const error = {}
		if (haveEmailError()) error.email = "Sai email"
		return error;
	}

	return (
		<Container className="reset-password component" >
			<h3 className="header" >ĐẶT LẠI MẬT KHẨU</h3>

			<form className="form" action="" >
				<div style={{ display: "inline-block" }}>
					<div className="field-container" >
						<input className={emailInputClass} id="email" name="email" type="text" autoComplete="off"
						       placeholder="Email" value={email} onChange={e => onEmailChange(e)}>
						</input>
						<SampleMessage show={(Validator.isEmpty(email) ? error.email === undefined : false) || (!haveEmailError)} message="VD: abc@xyz.com" />
						<ErrorMessage message={error.email} />
					</div>
					{
						errorMsg.status && errorMsg.id === RESET_PASSWORD_COMPLETE ?
							errorMsg.msg ?
								<Message error>{errorMsg.msg}</Message>
								: ""
							:
							resetPassword.success && resetPassword.type === RESET_PASSWORD_COMPLETE
								? <Message success>{resetPassword.msg}</Message>
								: ""
					}
					{
						resetPassword.success && resetPassword.type === RESET_PASSWORD_COMPLETE
							? <button type="button" className="back-login-button button"
							          onClick={() => {props.history.push('/login')}}
								> Quay lại đăng nhập </button>
							: <button type="button"  className="reset-button button" onClick={() => onSubmit()}>
								{
									resetPassword.isRequesting ?
										<Loader active inline inverted/>
										:
										<>Đặt lại mật khẩu</>
								}
							</button>
					}

				</div>
			</form >
		</Container >
	)
}

ResetPasswordPage.defaultProps = {
	name: "Reset Password"
}

ResetPasswordPage.propTypes = {
	name: PropTypes.string.isRequired
}

export default withRouter(ResetPasswordPage)

