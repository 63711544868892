import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classNames from 'classnames'
import { ReactComponent as SpeakerIcon } from "../../../assets/icons/speaker.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg"
import {saveWordToLocalStorageArray} from "../../../utils/saveWordToLocalStorageArray";
import {removeWordFromLocalStorageArray} from "../../../utils/removeWordFromLocalStorageArray";
import * as  md5 from 'md5';
import {useDispatch} from "react-redux";
import {searchWordDetail} from "../../../actions/search/searchAction";
import './ItemVocabularyComponent.css'

function ItemVocabularyComponent({ vocabulary, searchType, onClick }) {
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [avBookmark, setAvBookmark] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);
    const [vaBookmark, setVaBookmark] = useState(localStorage.getItem('va_bookmark') ? JSON.parse(localStorage.getItem('va_bookmark')) : []);
    const dispatch = useDispatch();
    useEffect(() => {
        if(searchType === 0){
            setIsBookmarked(avBookmark.some(item => item.id === vocabulary.id))
        } else if(searchType === 1){
            setIsBookmarked(vaBookmark.some(item => item.id === vocabulary.id))
        }
    },[]);

    useEffect(() => {
        if(searchType === 0){
            setAvBookmark(JSON.parse(localStorage.getItem('av_bookmark')))
        } else if(searchType === 1){
            setVaBookmark(JSON.parse(localStorage.getItem('va_bookmark')))
        }
    }, [isBookmarked])

    const onBookmark = () => {
        if(searchType === 0){
            let index = avBookmark.findIndex(word => word.id === vocabulary.id);
            if(index === -1){
                saveWordToLocalStorageArray(vocabulary, 'av_bookmark');
                setIsBookmarked(true);
            } else {
                setIsBookmarked(false);
                removeWordFromLocalStorageArray(vocabulary, 'av_bookmark');
            }
        } else if(searchType === 1){
            let index = vaBookmark.findIndex(word => word.id === vocabulary.id);
            if(index === -1){
                saveWordToLocalStorageArray(vocabulary, 'va_bookmark');
                setIsBookmarked(true);
            } else {
                setIsBookmarked(false);
                removeWordFromLocalStorageArray(vocabulary, 'va_bookmark');
            }
        }
    };
    const onClickSpeaker = async () => {
        let audio = new Audio("https://api.tudienviet.vn/dict/audio/uk/" + md5(vocabulary.word + '.h') + ".mp3?w=" + vocabulary.word);
        await audio.play();
    };
    return (
        <div className={"content-item"}>
            <div onClick={() => {
                dispatch(searchWordDetail(vocabulary.id, searchType));
                onClick();
            }}>
                <p>{vocabulary.word}</p>
                <p>{vocabulary.mean}</p>
            </div>
            <div className={"speaker"}>
                <SpeakerIcon onClick={() => onClickSpeaker()} />
            </div>
            <div className={classNames("mark", { "disable": !isBookmarked})}>
                <StarIcon onClick={() => onBookmark()} />
            </div>
        </div>
    )
}

ItemVocabularyComponent.propTypes = {

};


export default ItemVocabularyComponent
