import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Image, Container, Loader} from 'semantic-ui-react';
import LoginForm from '../forms/LoginForm.js'
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg"
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google.svg"
import './LoginComponent.css'
import { useSelector, useDispatch } from 'react-redux'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import {login, loginBackend} from "../../../actions/login/loginActions";
import {
    LOGIN_FACEBOOK_COMPLETE,
    LOGIN_FACEBOOK_REQUEST,
    LOGIN_GOOGLE_COMPLETE,
    LOGIN_GOOGLE_REQUEST
} from "../../../actions/types";
import RestorePopup from "../../signup/components/RestorePopup";

function LoginComponent(props) {
    const dispatch = useDispatch();
    const error = useSelector(state => state.error);
    const loginReducer = useSelector(state => state.login);
    const [isShowRestore, setIsShowRestore] = useState(false);

    const submitData = (data) => {
        dispatch(login(data.email, data.password))
    };

    const responseFacebook = (response) => {
        dispatch({type: LOGIN_FACEBOOK_REQUEST});
        dispatch(loginBackend({access_token: response.accessToken}, "facebook"))
    };

    const responseGoogle = (response) => {
        dispatch({type: LOGIN_GOOGLE_REQUEST});
        dispatch(loginBackend({access_token: response.accessToken}, "google"))
    };

    useEffect(() => {
        if(loginReducer.success && (loginReducer.type === LOGIN_FACEBOOK_COMPLETE || loginReducer.type === LOGIN_GOOGLE_COMPLETE)){
            if(loginReducer.isFirstTime !== undefined && loginReducer.isFirstTime){
                if(localStorage.getItem('av_bookmark') ||
                    localStorage.getItem('va_bookmark') ||
                    localStorage.getItem('recent_search') ||
                    localStorage.getItem('av_note') ||
                    localStorage.getItem('va_note')){
                    setIsShowRestore(true);
                } else {
                    window.location.reload();
                }
            }
        }
    }, [loginReducer]);

    return (
        isShowRestore ? <RestorePopup open={isShowRestore}/>
        :
        <Container className="login component" >
            <Image className="logo" src='logo.png' size='small' wrapped centered />
            <h3 className="header" >ĐĂNG NHẬP</h3>
            <LoginForm error={error} submit={(obj) => submitData(obj)} />
            <div style={{ display: "inline-block" }}>
                <hr />
                <p>Hay đăng nhập bằng</p>
                <button className="login-facebook">
                    <FacebookLogin
                        appId="880602642431374" //APP ID NOT CREATED YET
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={renderProps => (
                            <div onClick={renderProps.onClick}>
                                {
                                    loginReducer.type === LOGIN_FACEBOOK_REQUEST ?
                                        <Loader active inline inverted/>
                                        :
                                        <>
                                            <FacebookIcon />
                                            <p>Đăng nhập bằng Facebook</p>
                                        </>

                                }
                            </div>
                        )}
                    />
                </button>
                <button className="login-google">
                    <GoogleLogin
                        clientId="943008988402-gk1nlpv6knrdq1kisrd2csjfmheup1k0.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                        render={renderProps => (
                            <div onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                {
                                    loginReducer.type === LOGIN_GOOGLE_REQUEST ?
                                        <Loader active inline inverted/>
                                        :
                                        <>
                                            <GoogleIcon />
                                            <p>Đăng nhập bằng Google</p>
                                        </>

                                }
                            </div>
                        )}
                        buttonText="LOGIN WITH GOOGLE"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                    />
                </button>
            </div>
        </Container>
    )
}

LoginComponent.defaultProps = {
    name: ""
}

LoginComponent.propTypes = {
    name: PropTypes.string
}

export default LoginComponent

