import { combineReducers } from 'redux';
import errorReducer from "./errorReducer";
import loginReducer from "./login/loginReducer";
import authReducer from "./authReducer";
import signUpReducer from "./signup/signupReducer";
import resetPasswordReducer from "./reset-password/resetPasswordReducer";
import changePasswordReducer from "./change-password/changePasswordReducer";
import translateReducer from "./translate/translateReducer";
import searchReducer from "./search/searchReducer";
import importantWordReducer from "./important-word/importantWordReducer";
import lessonCategoryReducer from "./lesson-category/lessonCategoryReducer";
import lessonsReducer from "./lesson-lessons/lessonsReducer";
import lessonWordReducer from "./lesson-word/lessonWordReducer";
import restoreReducer from "./restore/restoreReducer";
import backupReducer from "./backup/backupReducer";


export default combineReducers({
    error: errorReducer,
    login: loginReducer,
    auth: authReducer,
    signUp: signUpReducer,
    resetPassword: resetPasswordReducer,
    changePassword: changePasswordReducer,
    translate: translateReducer,
    search: searchReducer,
    importantWord: importantWordReducer,
    lessonCategory: lessonCategoryReducer,
    lessons: lessonsReducer,
    lessonWord: lessonWordReducer,
    restore: restoreReducer,
    backup: backupReducer
});
