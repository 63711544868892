import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Image, Container, Loader} from 'semantic-ui-react';
import Validator from 'validator'
import ErrorMessage from './messages/ErrorMessage'
import './styles.css';
import { Message } from 'semantic-ui-react';
import {changePasswordAction} from './../../actions/change-password/changePasswordActions';
import {clearErrors} from "./../../actions/errorAction";
import {useSelector, useDispatch} from "react-redux";
import {CHANGE_PASSWORD_REQUEST,CHANGE_PASSWORD_COMPLETE} from "./../../actions/types";
import {withRouter} from "react-router-dom";

function ChangePasswordPage(props) {
  const [error, setError] = useState({})
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')

  const dispatch = useDispatch();
  const havePasswordError = () => { return Validator.isEmpty(password)}
  const haveConfirmError = () => { return confirm !== password }
  const passInputClass = error.password ? "error" : "";
  const errorMsg = useSelector(state => state.error);
  const changePassword = useSelector(state => state.changePassword);

  const onPasswordChange = e => { setPassword(e.target.value); setError({}) }
  const onConfirmChange = e => { setConfirm(e.target.value); setError({}) }

  const isEmpty = (o) => {
    for (var i in o) {
      if (o.hasOwnProperty(i)) return false;
    }
    return true;
  }

  const onSubmit = () => {
    const newError = validate()
    if (isEmpty(newError)){
      dispatch(clearErrors());
      dispatch(changePasswordAction(password,confirm));
    }
    else
      setError(newError)
  }

  const validate = () => {
    const error = {}
    if (havePasswordError()) error.password = "Sai mật khẩu"
    if (haveConfirmError()) error.confirm = "Mật khẩu không khớp"
    return error;
  }
  return (
    <Container className="change-password component" >
      <h3 className="header" >ĐỔI MẬT KHẨU</h3>

      <form className="form" action="" >
        <div style={{ display: "inline-block" }}>
          <div className="field-container">
            <input className={passInputClass} id="password" name="password" type="password" autoComplete="off"
                   placeholder="Mật khẩu mới" value={password} onChange={e => onPasswordChange(e)}>
            </input>
            <ErrorMessage message={error.password} />
          </div>
          <div className="field-container">
            <input className={passInputClass} id="confirm" name="confirm" type="password" autoComplete="off"
                   placeholder="Nhập lại mật khẩu" value={confirm} onChange={e => onConfirmChange(e)}>
            </input>
            <ErrorMessage message={error.confirm} />
          </div>

          {
            errorMsg.status && errorMsg.id === CHANGE_PASSWORD_COMPLETE ?
              errorMsg.msg.length
                ? <Message error list={errorMsg.msg}/>
                : ""
              :
              changePassword.success && changePassword.type === CHANGE_PASSWORD_COMPLETE
                ? <Message success>{changePassword.msg}</Message>
                : ""
          }
          {
            changePassword.success && changePassword.type === CHANGE_PASSWORD_COMPLETE
              ? <button type="button" className="back-login-button button"
                        onClick={() => {props.history.push('/login')}}
              > Quay lại đăng nhập </button>
              : <button type="button"  className="change-button button" onClick={() => onSubmit()}>
                {
                  changePassword.isRequesting ?
                    <Loader active inline inverted/>
                    :
                    <>Đặt lại mật khẩu</>
                }
              </button>
          }
        </div>
      </form >
    </Container >
  )
}

ChangePasswordPage.defaultProps = {
  name: "Change Password Page"
}

ChangePasswordPage.propTypes = {
  name: PropTypes.string.isRequired
}

export default withRouter(ChangePasswordPage)

