import {SIGNUP_REQUEST, SIGNUP_COMPLETE} from "../../actions/types";


const initialState = {
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SIGNUP_REQUEST:
            return {
                ...state,
                isSigningUp: true
            };
        case SIGNUP_COMPLETE:
            return {
                ...state,
                isSigningUp: false,
                success: action.success,
                payload: action.payload,
                error: action.error
            };
        default:
            return state;
    }
}
