import {RESTORE_REQUEST, RESTORE_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const restoreActions = () => (dispatch) => {
    dispatch({type: RESTORE_REQUEST});
    API.getByAuth('/dict/restore', (error, res) => {
        if (error) {
            dispatch({type: RESTORE_COMPLETE, success: false});
            dispatch(returnErrors(error, true, RESTORE_COMPLETE))
        } else {
            dispatch({type: RESTORE_COMPLETE, success: true, payload: res.data.data});
        }
    })
};
