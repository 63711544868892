import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ReactComponent as InfomationIcon } from "../../../assets/icons/information.svg"
import './ErrorMessage.css'

function ErrorMessage(props) {
    return (
        <>
            <div className="wrap-error">
                <span className={classNames("error-message",
                    { "visible": props.message },
                    { "hidden": props.message === '' })}>
                    {props.message}</span>
                <InfomationIcon className={classNames("wrong-icon",
                    { "visible": props.message },
                    { "hidden": props.message === '' })} />
            </div>
        </>
    )
}

ErrorMessage.defaultProps = {
    message: ''
}

ErrorMessage.propTypes = {
    message: PropTypes.string
}

export default ErrorMessage

