import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/SFProText-Bold.ttf'
import './assets/fonts/SFProText-Medium.ttf'
import './assets/fonts/SFProText-Regular.ttf'
import './assets/fonts/Montserrat-Bold.ttf'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux'
import store from './store'
import Theme from "./Theme";
import 'semantic-ui-css/semantic.min.css'
import "./themes/styles.css"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
          <Theme name={"royal-blue"}>
            <App />
          </Theme>
        </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
