import {RESET_PASSWORD_REQUEST, RESET_PASSWORD_COMPLETE} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const resetPasswordAction = (email) => (dispatch) => {
	dispatch({type: RESET_PASSWORD_REQUEST});
	let body = new FormData();
	body.append("email", email);
	API.post('/rest-auth/password/reset/', body, (error, res) => {
		if(error){
			dispatch({type: RESET_PASSWORD_COMPLETE, success: false});
			dispatch(returnErrors(error,true, RESET_PASSWORD_COMPLETE))
		} else {
			dispatch({type: RESET_PASSWORD_COMPLETE, success: true, msg: res.detail});
		}
	})
};
