import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import PropTypes from 'prop-types'
import { Container } from 'semantic-ui-react'
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg"
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg"
import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg"
import { ReactComponent as MessageIcon } from "../../assets/icons/ic_message.svg"
import { ReactComponent as LoginIcon } from "../../assets/icons/ic_logged_user.svg"
import { ReactComponent as LogoutIcon } from "../../assets/icons/ic_logout.svg"
import { ReactComponent as BackupIcon } from "../../assets/icons/ic_backup.svg"
import { ReactComponent as RestoreIcon } from "../../assets/icons/ic_restore.svg"
import { FacebookProvider, Share, } from 'react-facebook';
import {logoutActions} from "../../actions/logout/logoutActions";
// import {Helmet} from 'react-helmet'

import './styles.css'
import {useDispatch, useSelector} from "react-redux";

function SetupPage(props) {
	const dispatch = useDispatch()
	const onRateClick = () => {
		window.open("https://play.google.com/store/apps/details?id=com.hotuy.vdict", "_blank")
	}
	const onVersionClick = () => {
		props.history.push('/version')
	}
	const onLoginClick = () => {
		props.history.push("/login")
	}
	const onLogoutClick = () => {
		dispatch(logoutActions())
		props.history.push("")
	}
	const onBackupClick = () => {
		props.history.push('/setup/backup')
	}
	const onRestoreClick = () => {
		props.history.push('/setup/restore')
	}
	const {user} = useSelector(state=>state.auth)

	return (
		<Container className="setup">
			<h2>Vdict Dictionary</h2>
			<p>Cài đặt tài khoản</p>
			<Container className="content-container">
				<div className="title"><p>Cài đặt tài khoản của bạn</p></div>
				<div className="content-setup">
					<div className="item" onClick={() => onRateClick()}>
						<StarIcon />
						<div className="item-header">Đánh giá 5 sao</div>
						<ArrowRightIcon />
					</div>
					<FacebookProvider appId="379370942723873">
						<Share href="http://tudienviet.vn/" >
							{({ handleClick, loading }) => (
								<div className="item" >
									<ShareIcon />
									<div className="item-header">Chia sẻ với bạn bè</div>
									<button type="button" onClick={handleClick} />
								</div>

							)}
						</Share>
					</FacebookProvider>

					<div className="item" onClick={() => onBackupClick()}>
					    <BackupIcon />
					    <div className="item-header">Backup</div>
					    <ArrowRightIcon />
					</div>
					<div className="item" onClick={() => onRestoreClick()}>
						<RestoreIcon />
						<div className="item-header">Restore</div>
						<ArrowRightIcon />
					</div>
					<div className="item" onClick={() => onVersionClick()}>
						<MessageIcon />
						<div className="item-header">Vdict version</div>
						<ArrowRightIcon />
					</div>
					{
						user ?
							<div className="item" onClick={() => onLogoutClick()}>
								<LogoutIcon styles={{padding:'10px 5px 10px 30px !important'}} />
								<div className="item-header">Đăng xuất</div>
								<ArrowRightIcon />
							</div>
							:
							<div className="item" onClick={() => onLoginClick()}>
								<LoginIcon styles={{padding:'10px 5px 10px 30px'}}/>
								<div className="item-header">Đăng nhập</div>
								<ArrowRightIcon />
							</div>
					}
				</div>
			</Container>
		</Container >

	)
}

SetupPage.defaultProps = {
	name: "Set up Page",
}

SetupPage.propTypes = {
	name: PropTypes.string,
}

export default withRouter(SetupPage)

