import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Container, Grid} from 'semantic-ui-react'
import { ReactComponent as ReverseIcon } from '../../../assets/icons/reverse.svg'
import {translateActions} from '../../../actions/translate/translateActions'
import { useSelector, useDispatch } from 'react-redux'
import './SentenceTranslateComponent.css'
import SpeakerComponent from "./SpeakerComponent";

function SentenceTranslateComponent(props) {
	const [language, setLanguage] = useState("en_vi")
	const [input, setInput] = useState("")
	const dispatch = useDispatch();
	const translateReducer = useSelector(state=>state.translate);
	useEffect(()=>{
		dispatch(translateActions(input,language))
	},[language])

	useEffect(()=>{
		const timer = setTimeout(()=>{
			if(input !== "")
				dispatch(translateActions(input,language))
		},750)
		return ()=>clearTimeout(timer)
	},[input])

	const onReverseClick = () => {
		const newLanguage = language === "en_vi" ? "vi_en" : "en_vi";
		setLanguage(newLanguage)
	}

	const onInputChange = (e) => {
		setInput(e.target.value)
	}

	const OnTranslate = () => {
		dispatch(translateActions(input,language))
	}

	const GetLangTitle = (isInput = true)=>{
		return GetLangCode(isInput) === "en" ? "Tiếng Anh" : "Tiếng Việt"
	}

	const GetLangCode = (isInput = true)=>{
		return isInput ? language.slice(0,2) : language.slice(-2)
	}

	return (
		<Container className="sentense-translate">
			<h2>VDict Dictionary</h2>
			<p>Dịch câu</p>
			<Container className="translate-container">
				<Grid columns={2} style={{ position: "relative" }}>
					<Grid.Row >
						<Grid.Column computer={8} tablet={16} mobile={16}>
							<p>{GetLangTitle()}</p>
							<textarea className="input-area" onChange={(e) => onInputChange(e)} placeholder="Nội dung của bạn..." />
							<SpeakerComponent lang={GetLangCode()} wordToPronounce={input}/>
						</Grid.Column>
						<Grid.Column computer={8} tablet={16} mobile={16}>
							<p>{GetLangTitle(false)}</p>
							<textarea className="result-area"  placeholder="Nội dung kết quả của bạn..." disabled
							          value={
							          	input !== ""
									          ? translateReducer.isRequesting
									            ? "Đang dịch..."
									            : translateReducer.success
									              ? translateReducer.payload.data.data
									              : ""
									          : "Nội dung kết quả của bạn..."
							          }/>
							<SpeakerComponent
								lang={GetLangCode(false)}
								wordToPronounce={translateReducer.success
										? translateReducer.payload.data.data
										: ""
							}/>
						</Grid.Column>
					</Grid.Row>
					<div className="swapper-reverse-icon">
						<button className="svg-icon" onClick={() => onReverseClick()}>
							<ReverseIcon />
						</button>
					</div>
				</Grid>
			</Container>
			<button className="translate-button" onClick={()=>OnTranslate()}>Dịch câu</button>
		</Container>
	)
}

SentenceTranslateComponent.defaultProps = {
	name: "Sentence Translate Component"
}

SentenceTranslateComponent.propTypes = {
	name: PropTypes.string
}

export default SentenceTranslateComponent

