import React, { useState, useEffect } from "react";
import { ReactComponent as BackArrowIcon } from "../assets/icons/back-arrow.svg";
import { ReactComponent as SpeakerIcon } from "../assets/icons/speaker.svg";
import { ReactComponent as UndoIcon } from "../assets/icons/undo.svg";
import { ReactComponent as CorrectIcon } from "../assets/icons/correct.svg"
import { ReactComponent as IncorrectIcon } from "../assets/icons/incorrect.svg"
import "./ListeningPracticeComponent.css";
import * as md5 from "md5";
import answerFailAudio from "../assets/audios/answer_fail.mp3";
import answerRightAudio from "../assets/audios/answer_right.mp3";
import undoAudio from "../assets/audios/tone.mp3";
import touchAudio from "../assets/audios/touch.mp3";

function ListeningPracticeComponent(props) {
    const [listData, setListData] = useState(props.listData);
    const [indexWord, setIndexWord] = useState(0);
    const [isStart, setIsStart] = useState(false);

    useEffect(() => {
        setListData(props.listData)
    },[props.listData])

    const gotoNewWord = () => {
        if(indexWord === listData.length - 1){
            props.onGoBack()
        } else {
            setIndexWord(indexWord + 1)
        }
    };




    const AnswerCorrect = () => {
        useEffect(() => {
            let audio = new Audio(answerRightAudio);
            audio.play();
        },[]);
        return (
            <div style={{
                height: "100px",
                width: "200px",
                background: "#69e781",
                textAlign: "center",
                color: "#fff",
                borderRadius: "20px"
            }}>
                <CorrectIcon style={{ width: "50px", fill: "#fff" }} />
                <p>Đáp án đúng</p>
            </div>
        )
    };

    const AnswerIncorrect = () => {
        useEffect(() => {
            let audio = new Audio(answerFailAudio);
            audio.play();
        },[]);
        return (
            <div style={{
                height: "100px",
                width: "200px",
                background: "#f76f8e",
                textAlign: "center",
                color: "#fff",
                borderRadius: "20px"
            }}>
                <IncorrectIcon style={{ width: "50px", fill: "#fff" }} />
                <p>Đáp án sai</p>
            </div>
        )
    };


    const MainComponent = () => {
        return (
            <section id={"listening-practice"}>
                <div className={"listening-practice-container"}>
                    <div className={"header"}>
                        <div className={"back"} style={{ position: "absolute" }} onClick={() => props.onGoBack()}>
                            <BackArrowIcon />
                        </div>
                        <div className={"title"} style={{ textAlign: "center" }}>
                            Luyện Nghe
                        </div>
                    </div>
                    <div className={"instruction"}>
                        <span>Ghép đúng từ hoặc cụm từ  theo đúng thứ tự để có đáp án đúng.</span>
                    </div>
                </div>
                <div className={"action"}>
                    <button className={"start"} onClick={() => setIsStart(true)}>Bắt đầu</button>
                </div>
            </section>
        )
    };


    const PracticeComponent = ({word}) => {
        const [answer, ] = useState(word.word);
        const [userAnswer, setUserAnswer] = useState([]);
        const [indexActive, setIndexActive] = useState([]);
        const [listCharacter, setListCharacter] = useState([]);
        const [isCorrect, setIsCorrect] = useState(false);
        const [isDisplayResult, setIsDisplayResult] = useState(false);

        useEffect(() => {
            onClickSpeaker();
            setListCharacter([...shuffle(word.word).slice("")]);
        },[]);

        useEffect(() => {
            let timeout;
            setIsDisplayResult(false);
            if(userAnswer.length === answer.length){
                if(userAnswer.join("") === answer){
                    setIsCorrect(true)
                    timeout = setTimeout(()=>{
                        gotoNewWord()
                    }, 2000)
                } else {
                    setIsCorrect(false)
                }
                setIsDisplayResult(true);
            }
            return () => {
                clearTimeout(timeout)
            }
        },[userAnswer]);


        const BlankCharacter = () => {
            let blanks = [];
            for (let i = 0; i < (answer.length - userAnswer.length); i++) {
                blanks.push(<li><button /></li>)
            }
            return blanks;
        };

        const onClickCharacter = async (character, index) => {
            await playAudio(touchAudio);
            if (userAnswer.length < answer.length) {
                setUserAnswer([...userAnswer, ...character]);
                setIndexActive([...indexActive, index]);
            }
        };

        const playAudio = async (src) => {
            let audio = new Audio(src);
            await audio.play();
        };

        const onClickUndo = async () => {
            if(!isCorrect){
                await playAudio(undoAudio);
                userAnswer.pop();
                indexActive.pop();
                setUserAnswer([...userAnswer]);
                setIndexActive([...indexActive]);
            }
        };


        const shuffle = (string) => {
            let a = string.split(""),
                n = a.length;

            for(let i = n - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                let tmp = a[i];
                a[i] = a[j];
                a[j] = tmp;
            }
            return a.join("");
        };

        const onClickSpeaker = async () => {
            let audio = new Audio("https://api.tudienviet.vn/dict/audio/uk/" + md5(word.word + '.h') + ".mp3?w=" + word.word);
            await audio.play();
        };

        return (
            <section id={"listening-practice"}>
                <div className={"listening-practice-container"}>
                    <div className={"header"}>
                        <div className={"back"} onClick={() => props.onGoBack()}>
                            <BackArrowIcon />
                        </div>
                        <div className={"title"}>
                            Luyện Nghe
                        </div>
                        {/*<div className={"answer"}>*/}
                        {/*    <button onClick={()=> checkAnswer()}>Đáp án</button>*/}
                        {/*</div>*/}
                    </div>
                    <div className={"speaker"}>
                        <SpeakerIcon onClick={() => onClickSpeaker()}/>
                    </div>
                    {
                        isCorrect ?
                           <>
                               <div className={"header child"}>
                                   <div className={"title"}>
                                       Nghĩa của từ khóa
                                   </div>
                               </div>
                               <div className={"content"}>
                                   <span>{word.mean}</span>
                               </div>
                           </>
                            : null
                    }
                    <div className={"header child"}>
                        <div className={"title"}>
                            Lựa chọn ký tự cho đáp án
                        </div>
                    </div>
                    <div className={"content"}>
                        <ul>
                            {
                                listCharacter.map((character, index) => (
                                    <li>
                                        <button
                                            className={indexActive.includes(index) ? "active" : ""}
                                            onClick={() => onClickCharacter(character, index)}>
                                            {character}
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className={"header child"}>
                        <div className={"title"}>
                            Đáp án của bạn
                        </div>
                        <div className={"undo"}>
                            <UndoIcon onClick={() => onClickUndo()} />
                        </div>
                    </div>
                    <div className={"content"}>
                        <ul>
                            {
                                userAnswer.map((character => (
                                    <li><button className={"active"}>{character}</button></li>
                                )))
                            }
                            <BlankCharacter />
                        </ul>
                    </div>
                </div>
                <div className={"answer-checked"}>
                    {
                        isDisplayResult ? isCorrect ? <AnswerCorrect /> : <AnswerIncorrect /> : ""
                    }
                </div>
            </section>
        )
    };

    return (isStart ? <PracticeComponent word={listData[indexWord]} /> : <MainComponent />)
}
export default ListeningPracticeComponent
