import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import {ReactComponent as StarIcon} from "../../assets/icons/star.svg";
import './StarBookmarkComponent.css'
import {saveWordToLocalStorageArray} from "../../utils/saveWordToLocalStorageArray";
import {removeWordFromLocalStorageArray} from "../../utils/removeWordFromLocalStorageArray";

StarBookmarkComponent.propTypes = {
	
};

function StarBookmarkComponent(props) {
	const [isBookmarked, setIsBookmarked] = useState(false);
	const [avBookmark, setAvBookmark] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);

	useEffect(() => {
			setAvBookmark(JSON.parse(localStorage.getItem('av_bookmark')))
	}, [isBookmarked])

	useEffect(() => {
		setIsBookmarked(avBookmark.findIndex(word => word.word.toString().toLowerCase() === props.word.word.toString().toLowerCase()) !== -1)
	}, [])

	const onBookmark = () => {
		console.log(localStorage.getItem('av_bookmark'));
		let index = -1;
		if(avBookmark){
			index = avBookmark.findIndex(word => word.word.toLowerCase() === props.word.word.toLowerCase());
		}
		if(index === -1){
			saveWordToLocalStorageArray(props.word, 'av_bookmark');
			setIsBookmarked(true);
		} else {
			removeWordFromLocalStorageArray(props.word, 'av_bookmark');
			setIsBookmarked(false);
		}
	}
	return (
		<div className={classNames("mark", {"disable": !isBookmarked})}>
			<StarIcon onClick={() =>  onBookmark()}/>
		</div>
	);
}

export default StarBookmarkComponent;