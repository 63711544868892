import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './styles.css'
import {Image, Grid, Message, Loader} from 'semantic-ui-react'
import {LessonCategoryActions} from "../../actions/lesson-category/LessonCategoryActions";
import {clearErrors} from "../../actions/errorAction";
import {useParams, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {LESSON_CATEGORY_COMPLETE} from "../../actions/types";
import CategoryItemComponent from "./components/CategoryItemComponent";

LessonCategoryPage.propTypes = {
	id: PropTypes.number
};

LessonCategoryPage.defaultProps = {
	id: 0
};

function LessonCategoryPage(props) {
	const dispatch = useDispatch()
	const {id} = useParams()
	const [state,setState] = useState({title: id === '0' ? "Gói từ vựng VIP luyện thi" : "Gói từ vựng VIP SGK"})
	const lessonCategoryReducer = useSelector(state=>state.lessonCategory)
	const error = useSelector(state=>state.error)

	useEffect(()=>{
		dispatch(clearErrors())
		dispatch(LessonCategoryActions(id))
	},[id])
	return (
		<Fragment>
			<div className={"lesson-category"}>
				<h3>{state.title}</h3>
				<div className={"content-wrapper"}>
					<div className={"lesson-content"}>
						<div className={"categories"}>
							{
								error.status && error.id === LESSON_CATEGORY_COMPLETE
								? <Message color='red'>Có lỗi xảy ra</Message>
								: ""
							}
							{
								lessonCategoryReducer.isRequesting ? <Loader active inline inverted/>
								: lessonCategoryReducer.payload ?
									lessonCategoryReducer.payload.map(item=>
										<CategoryItemComponent
											key={item.id}
											image={item.image}
											name={item.name}
											description={item.description}
											code={item.code}
										/>
									)
									: <Message color='yellow'>Có lỗi xảy ra</Message>
							}

						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default withRouter(LessonCategoryPage);