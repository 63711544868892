import {
    SEARCH_WORD_COMPLETE,
    SEARCH_WORD_DETAIL_COMPLETE,
    SEARCH_WORD_DETAIL_REQUEST,
    SEARCH_WORD_REQUEST
} from "../../actions/types";

const initialState = {
};

export default function(state = initialState, action){
    switch (action.type) {
        case SEARCH_WORD_REQUEST:
            return {
                ...state,
                isFetching: true,
                success: action.success
            };
        case SEARCH_WORD_COMPLETE:
            return {
                ...state,
                isFetching: false,
                data: action.data,
                type: action.type,
                success: action.success
            };
        case SEARCH_WORD_DETAIL_REQUEST: {
            return {
                ...state,
                isFetching: true,
                success: action.success
            }
        }
        case SEARCH_WORD_DETAIL_COMPLETE:
            return {
                ...state,
                isFetching: false,
                data: action.data,
                type: action.type,
                success: action.success
            };
        default:
            return state

    }
}
