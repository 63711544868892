import React, {useEffect, useState} from "react";
import "./styles.css";
import ListeningPracticeComponent from "../../common-components/ListeningPracticeComponent";
import ReviewComponent from "./components/ReviewComponent";
import {useParams} from "react-router-dom";
import {clearErrors} from "../../actions/errorAction";
import {LessonWordActions} from "../../actions/lesson-word/lessonWordActions";
import {LESSONS_COMPLETE} from "../../actions/types";
import {Loader, Message} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import WordItemComponent from './components/WordItemComponent'
import SearchComponent from "../home/components/SearchComponent";

function LessonWordPage() {
	const [mode, setMode] = useState(null);
	const [listData, setListData] = useState([]);
	const [keyword, setKeyword] = useState("");
	const {code,id,name} = useParams()
	const lessonWordReducer = useSelector(state=>state.lessonWord)
	const error = useSelector(state=>state.error)
	const dispatch = useDispatch()

	useEffect(()=>{
		dispatch(clearErrors())
		dispatch(LessonWordActions(code,id))
	},[code,id])

	useEffect(()=>{
		setListData(lessonWordReducer.payload)
	},[lessonWordReducer.payload])

	const ModeComponent = () => {
		switch (mode) {
			case "review":
				return <ReviewComponent code={code} listData={listData} onGoBack={() => setMode(null)} />;
			case "listening":
				return <ListeningPracticeComponent code={code} listData={listData} onGoBack={() => setMode(null)} />;
			default:
				return (
					<section id={"lesson-word"}>
						<div className={"action"}>
							<button className={"btn-quick-test"} onClick={() => setMode("review")}>Kiểm tra nhanh</button>
							<button className={"btn-listening-practice"} onClick={() => setMode("listening")}>Luyện nghe</button>
						</div>
						<div className={"lesson-word-container"}>
							<div className={"header"}>
								<div className={"title"}>
									{name}
								</div>
							</div>
							{
									error.status && error.id === LESSONS_COMPLETE
										? <Message color='red'>Có lỗi xảy ra</Message>
										: ""
							}
							{
								lessonWordReducer.isRequesting ? <div className={"loader-wrapper"}><Loader active inline /> </div>
									: lessonWordReducer.success && lessonWordReducer.payload
									? lessonWordReducer.payload.map(item=>
										<WordItemComponent
											key={item.id}
											item={item}
											code={code}
											onClick={(word)=>{ window.scrollTo({ top: 0, behavior: 'smooth', });setKeyword(word)}}
										/>)
									: ""
							}
						</div>
					</section>
				)
		}
	};

	return (
		<>
			<SearchComponent keyword={keyword}/>
			<ModeComponent />
		</>
	)
}
export default LessonWordPage
