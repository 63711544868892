import {AUTH_COMPLETE, AUTH_REQUEST} from "../actions/types";

const initialState = {
    isFetching: true
};
export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case AUTH_COMPLETE:
            return {
                ...state,
                isFetching: false,
                user: action.user
            };
        default:
            return state
    }
}
