import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {LESSONS_COMPLETE} from "../../actions/types";
import {Loader, Message} from "semantic-ui-react";
import LessonItemComponent from "./components/LessonItemComponent";
import './styles.css'
import {useDispatch, useSelector} from "react-redux";
import {clearErrors} from "../../actions/errorAction";
import {LessonsActions} from "../../actions/lesson-lessons/LessonsActions";
import {useParams,withRouter} from "react-router-dom";
LessonsPage.propTypes = {

};

function LessonsPage(props) {
	const dispatch = useDispatch()
	const {code,name} = useParams()
	const [state,setState] = useState({})
	const lessonsReducer = useSelector(state=>state.lessons)
	const error = useSelector(state=>state.error)
	useEffect(()=>{
			dispatch(clearErrors())
			dispatch(LessonsActions(code))
	},[code])
	useEffect(()=>{
		if(props.location.state){
			setState(props.location.state)
		}
	},[props.location.state !== undefined])
	return (
		<Fragment>
			<div className={"list-lesson"}>
				<h3>{name}</h3>
				<div className={"content-wrapper"}>
					<div className={"lesson-content"}>
						<div className={"lessons"}>
							{
								error.status && error.id === LESSONS_COMPLETE
									? <Message color='red'>Có lỗi xảy ra</Message>
									: ""
							}
							{
								lessonsReducer.isRequesting ? <Loader active inline />
									: lessonsReducer.success && lessonsReducer.payload
										? lessonsReducer.payload.map(item=>
											<LessonItemComponent
												code={code}
												image={state.image}
												name={item['name_vi']}
												count={item.count}
												id={item.id}
											/>
										)
										: ""
							}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default withRouter(LessonsPage);