import React from "react";
import "./FooterComponent.css"
const FooterComponent = () => {
	return(
		<section id={"footer"}>
			<div className={"footer-container"}>
				<ul className={"footer-menu"}>
					<li className={"menu-item left"}>
						<a href={"#"}>Giới thiệu</a>
					</li>
					<li className={"menu-item left"}>
						<a href={"mailto:ti.tuanha@outook.com"} target={"_blank"}>Liên hệ</a>
					</li>
					<li className={"menu-item right"}>
						<a href={"#"}>Copyright 2020 @ Vdict</a>
					</li>
				</ul>
			</div>
		</section>
	)
};

export default FooterComponent
