import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Container, Loader} from "semantic-ui-react";
import {ReactComponent as BackIcon} from "../../../assets/icons/back-arrow.svg";
import './BackUpComponent.css'
import { withRouter } from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {backupActions} from "../../../actions/backup/backupActions";
import {BACKUP_COMPLETE, BACKUP_REQUEST, BACKUP_CLEAR_HISTORY} from "../../../actions/types";
const BackUpComponent = props => {
	const [recentSearch, ] = useState(localStorage.getItem('recent_search') ? JSON.parse(localStorage.getItem('recent_search')) : []);
	const [avBookmark, ] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);
	const [vaBookmark, ] = useState(localStorage.getItem('va_bookmark') ? JSON.parse(localStorage.getItem('va_bookmark')) : []);
	const [avNote, ] = useState(localStorage.getItem('av_note') ? JSON.parse(localStorage.getItem('av_note')) : []);
	const [vaNote, ] = useState(localStorage.getItem('va_note') ? JSON.parse(localStorage.getItem('va_note')) : []);

	const dispatch = useDispatch();
	const backup = useSelector(state => state.backup);


	const onBackClick = () => {
		props.history.goBack();
		dispatch({type: BACKUP_CLEAR_HISTORY});
	};

	const onClickBackUp = () => {
		let data = {
			"av_bookmark": avBookmark,
			"va_bookmark": vaBookmark,
			"recent_search": recentSearch,
			"av_note": avNote,
			"va_note": vaNote
		};
		dispatch(backupActions(data))
	};

	console.log(avNote)
	return (
		<Container id="backup">
			<h2>Vdict Dictionary</h2>
			<p/>
			<Container className="content-container">
				<div className="title"><p>Back Up</p></div>
				<div className={"content"}>
					<p>Từ gần đây ({recentSearch.length})</p>
					<p>Từ của bạn ({[...avBookmark,...vaBookmark].length})</p>
					<p>Từ của bạn ({[...avNote,...vaNote].length})</p>
					{
						backup.type === BACKUP_REQUEST ?
							<div style={{"padding": "30px 0", textAlign: "center"}}>
								<Loader active inline/>
							</div>
							:
							backup.type === BACKUP_COMPLETE ?
								backup.success ?
									<>
										<p style={{color: "#21ba45"}}>Backup thành công</p>
										<Button className={"backupBtn"} onClick={()=>onBackClick()}>Trở lại</Button>
									</>
									:
									<>
										<p style={{color: "red"}}>Thất bại</p>
										<Button className={"backupBtn"} onClick={()=>onClickBackUp()}>Thử lại</Button>
									</>
								:
								<Button className={"backupBtn"} onClick={()=>onClickBackUp()}>Back up now</Button>
					}
				</div>
				<button className="svg-icon" onClick={() => onBackClick()}>
					<BackIcon />
				</button>
			</Container>
		</Container>
	);
};

BackUpComponent.propTypes = {

};

export default withRouter(BackUpComponent);
