import {
    SEARCH_WORD_COMPLETE,
    SEARCH_WORD_DETAIL_COMPLETE,
    SEARCH_WORD_DETAIL_REQUEST,
    SEARCH_WORD_REQUEST
} from "../types";
import API from "../../utils/API";
import {returnErrors} from "../errorAction";

export const search = (word, type = 0) => (dispatch) => {
    dispatch({type: SEARCH_WORD_REQUEST});
    API.get(`/dict/search?type=${type}&q=${word}`,(error, res) => {
        if(error){
            dispatch({type: SEARCH_WORD_COMPLETE, success: false});
            dispatch(returnErrors(error,true, SEARCH_WORD_COMPLETE))
        } else {
            dispatch({type: SEARCH_WORD_COMPLETE, success: true, data: res.data});
        }
    })
};

export const searchWordDetail = (id, type = 0) => (dispatch) => {
    dispatch({type: SEARCH_WORD_DETAIL_REQUEST});
    API.get(`/dict/detail?type=${type}&id=${id}`, (error, res) => {
        if(error){
            dispatch({type: SEARCH_WORD_DETAIL_COMPLETE, success: false});
            dispatch(returnErrors(error,true, SEARCH_WORD_DETAIL_COMPLETE))
        } else {
            dispatch({type: SEARCH_WORD_DETAIL_COMPLETE, success: true, data: res.data});
        }
    })
};
