import React, {useState} from 'react'
import {Confirm } from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {backupActions} from "../../../actions/backup/backupActions";
import {BACKUP_COMPLETE, BACKUP_REQUEST} from "../../../actions/types";

function RestorePopup({open}) {
    const [isOpen, setOpen] = useState(open);
    const [recentSearch, ] = useState(localStorage.getItem('recent_search') ? JSON.parse(localStorage.getItem('recent_search')) : []);
    const [avBookmark, ] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);
    const [vaBookmark, ] = useState(localStorage.getItem('va_bookmark') ? JSON.parse(localStorage.getItem('va_bookmark')) : []);
    const [avNote, ] = useState(localStorage.getItem('av_note') ? JSON.parse(localStorage.getItem('av_note')) : []);
    const [vaNote, ] = useState(localStorage.getItem('va_note') ? JSON.parse(localStorage.getItem('va_note')) : []);

    const dispatch = useDispatch();
    const backup = useSelector(state => state.backup);

    const onClickBackUp = () => {
        let data = {
            "av_bookmark": avBookmark,
            "va_bookmark": vaBookmark,
            "recent_search": recentSearch,
            "av_note": avNote,
            "va_note": vaNote
        };
        dispatch(backupActions(data))
    };

    const onCancel = () => {
        if(backup.type !== BACKUP_REQUEST || backup.type !== BACKUP_COMPLETE){
            localStorage.removeItem('recent_search');
            localStorage.removeItem('av_bookmark');
            localStorage.removeItem('va_bookmark');
            localStorage.removeItem('av_note');
            localStorage.removeItem('va_note');
        }
        window.location.reload()
    };

    if(backup.type === BACKUP_COMPLETE && backup.success){
        window.location.reload()
    }

    return (
        <div>
            <Confirm
                open={isOpen}
                content={
                    backup.type === BACKUP_REQUEST ? 'Đang khôi phục dữ liệu...' : backup.type === BACKUP_COMPLETE ? 'Khôi phục dữ liệu hoàn tất!': 'Bạn có muốn khôi phục dữ liệu trước đó không?'
                }
                onCancel={() => onCancel()}
                onConfirm={() => onClickBackUp()}
            />
        </div>
    )
}
RestorePopup.defaultProps = {
    open: false
}

export default RestorePopup
