import {ReactComponent as RecentIcon} from "../../../assets/icons/rencent.svg";
import {ReactComponent as VocabularyIcon} from "../../../assets/icons/vocabulary.svg";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/arrow-right.svg";
import React from "react";
import PropTypes from "prop-types";

const SuggestionResultItemComponent = (props) => {
    return (
        <div className={"suggestion-item"} onClick={()=>props.onClickItem()}>
            <div className={"icon"}>
              {props.isRecent ? <RecentIcon/> : <VocabularyIcon/>}
            </div>
            <div className={"vocabulary"}>
                <p className={"word"}>{props.vocabulary.word}</p>
                <p className={"word-info"}>{props.vocabulary.mean}</p>
            </div>
            <div className={"next-icon"}>
                <ArrowRightIcon/>
            </div>
        </div>
    )
};

SuggestionResultItemComponent.defaultProps = {

};

SuggestionResultItemComponent.propTypes = {
    vocabulary: PropTypes.object.isRequired
};

export default SuggestionResultItemComponent
