import React, {useEffect, useRef, useState} from "react";
import ListVocabularyComponent from "./components/ListVocabularyComponent";
import "./styles.css";
import FilterVocabularyComponent from "./components/FilterVocabularyComponent";
import ReviewComponent from "../recent-words/components/ReviewComponent";
import VdictDictionaryComponent from "../home/components/VdictDictionaryComponent";
import {saveWordToLocalStorageArray} from "../../utils/saveWordToLocalStorageArray";
import {ReactComponent as SearchIcon} from "../../assets/icons/search.svg";
import {ReactComponent as AngleDownIcon} from "../../assets/icons/angle-down.svg";
import {Button} from "semantic-ui-react";
import {SEARCH_WORD_DETAIL_COMPLETE} from "../../actions/types";
import SearchResultComponent from "../home/components/SearchResultComponent";
import {useSelector} from "react-redux";
import ListeningPracticeComponent from "../../common-components/ListeningPracticeComponent";

function MyVocabularyComponent() {
	const [avBookmark, ] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);
	const [vaBookmark, ] = useState(localStorage.getItem('va_bookmark') ? JSON.parse(localStorage.getItem('va_bookmark')) : []);
	const [list, setList] = useState([...avBookmark, ...vaBookmark]);
	const [displayResult, setDisplayResult] = useState(false);
	const [sort, setSort] = useState({
		fn: undefined
	})

	const [mode, setMode] = useState(null);
	const [isDisplayDropdownContent, setDisplayDropdownContent] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [searchResult, setSearchResult] = useState([]);
	const [searchTypeList, ] = useState([
		{
			text: "Anh - Việt",
			id: 0
		},
		{
			text: "Việt - Anh",
			id: 1
		}
	]);

	const [searchType, setSearchType] = useState(0);
	const searchData = useSelector(state => state.search);
	const onClickSearchType = (e, id) => {
		e.preventDefault();
		setSearchType(id);
	};

	const filterRef = useRef(null);
	useOutsideAlerter(filterRef);
	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setDisplayDropdownContent(false)
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const onSearch = (e) => {
		let regex = new RegExp(`^${e.target.value.trim()}`,'g');
		setSearchResult(list.filter(({word}) => word.match(regex)).sort((a, b) => a.word.localeCompare(b.word)))
	}

	const onSortAZ = () => {
		setSort({ fn: (a, b) => (a.word > b.word) ? 1 : (b.word > a.word) ? -1 : 0 })
	}
	const onSortRemind = () => {
		setSort({ fn: (a, b) => a.isReminded - b.isReminded })
	}
	const onRemind = (vocabulary) => {
		// Call Apis here
		let data = list.map(e => {
			if (e.word === vocabulary.word)
				e.isReminded = !vocabulary.isReminded;
			return e
		})
		setList(data)
	}
	const onDelete = () => {
		// Call Apis here
		setList([])
	}
	const onRemindAll = () => {
		// Call Apis here
		let data = list.map(e => { e.isReminded = true; return e })
		setList(data)
	}
	const onDeleteRemindAll = () => {
		// Call Apis here
		let data = list.map(e => { e.isReminded = false; return e })
		setList(data)
	}
	const onBookmark = (vocabulary) => {
		// Call Apis here
		saveWordToLocalStorageArray(vocabulary, 'my_words');
	}

	const ModeComponent = () => {
		switch (mode) {
			case "quick-test":
				return <ReviewComponent listData={list} onGoBack={() => setMode(null)} />;
			case "listening":
				return <ListeningPracticeComponent listData={list} onGoBack={() => setMode(null)} />;
			default:
				return (
					<section id={"my-vocabulary"}>
						{
							!displayResult ?
								<div className={"action"}>
									<button className={"btn-quick-test"} onClick={() => setMode("quick-test")}>Kiểm tra nhanh</button>
									<button className={"btn-listening-practice"} onClick={() => setMode("listening")}>Luyện nghe</button>
								</div>
								: ""
						}
						<div className={"my-vocabulary-container"}>
							{
								displayResult ?
									searchData.success && !searchData.isFetching && searchData.type === SEARCH_WORD_DETAIL_COMPLETE ?
										<SearchResultComponent isDisplay={displayResult} searchType={searchTypeList[searchType]} data={searchData.data}/>
										: ""
									:
									<>
										<div className={"header"}>
											<div className={"title"}>
												Danh sách từ của bạn
											</div>
											<FilterVocabularyComponent
												onSortAZ={() => onSortAZ()}
												onSortRemind={() => onSortRemind()}
												onDelete={() => onDelete()}
												onRemindAll={() => onRemindAll()}
												onDeleteRemindAll={() => onDeleteRemindAll()} />
										</div>
										{
											keyword.trim() === "" ?
												list.length ?
													<ListVocabularyComponent
														sort={sort.fn}
														data={list}
														searchType={searchType}
														onClickItem={()=>setDisplayResult(true)}
														onBookmark={(vocabulary) => onBookmark(vocabulary)} />
													:
													<div style={{padding: "30px"}}>Danh sách trống!</div>
												:
												searchResult.length ?
													<ListVocabularyComponent
														sort={sort.fn}
														data={searchResult}
														searchType={searchType}
														onClickItem={()=>setDisplayResult(true)}
														onBookmark={(vocabulary) => onBookmark(vocabulary)} />
													:
													<div style={{padding: "30px"}}>Không tìm thấy <b>{keyword}</b>!</div>
										}
									</>
							}
						</div>

					</section >
				)
		}
	};
	return (
		<>
			<VdictDictionaryComponent />
			<div className={"suggestion-container"}>
				<div className={"suggestion-wrapper"}>
					<div className={"suggestion-action"}>
						<div className={"suggestion-box"}>
							<SearchIcon/>
							<input type={"text"}
							       value={keyword}
							       onChange={(e) => {
								       setKeyword(e.target.value);
								       setMode(null);
								       onSearch(e)
							       }}
							       onFocus={() => {
								       setDisplayResult(false);
							       }}
							/>
						</div>
						<div className={"dropdown"} ref={filterRef}>
							<div className={"filter"} onClick={()=>setDisplayDropdownContent(!isDisplayDropdownContent)}>
								<span>{searchTypeList[searchType].text}</span> <AngleDownIcon/>
							</div>
							<div className={"dropdown-content"}
							     onClick={()=>setDisplayDropdownContent(false)}
							     style={isDisplayDropdownContent? {display: "block"} : {display: "none"}}>
								{
									searchTypeList.map((item, index) => <a key={index} href={"#"} onClick={e => onClickSearchType(e, item.id)}>{item.text}</a>)
								}
							</div>
						</div>
						<Button className={"btn-suggestion"} onClick={()=>{}}>Tra từ</Button>
					</div>
				</div>
			</div>
			<ModeComponent />
		</>
	)

}
export default MyVocabularyComponent
