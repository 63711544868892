import ImportantFilterComponent from "./components/ImportantFilterComponent";
import ImportantListVocabularyComponent from "./components/ImportantListVocabularyComponent"
import VdictDictionaryComponent from "../home/components/VdictDictionaryComponent";
import ImportantListeningPracticeComponent from "./components/ImportantListeningPracticeComponent";
import SearchComponent from "../home/components/SearchComponent";
import ReviewComponent from "./components/ReviewComponent";
import {Pagination} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {Message} from "semantic-ui-react";

import { ReactComponent as VocabularyIcon } from "../../assets/icons/vocabulary.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as BackArrowIcon } from "../../assets/icons/back-arrow.svg";

import 'react-virtualized/styles.css'
import "./styles.css"

import {importantWordActions,clearImpotantWord} from '../../actions/important-word/importantWordActions';
import {IMPORTANT_WORD_COMPLETE, SEARCH_WORD_DETAIL_COMPLETE} from "../../actions/types";
import {clearErrors} from "../../actions/errorAction";
import {useDispatch, useSelector} from "react-redux";
import SearchResultComponent from "../home/components/SearchResultComponent";
import {saveWordToLocalStorageArray} from "../../utils/saveWordToLocalStorageArray";

const listData = [
	{title:"3000 Oxford Words",key:"essential3000"},
	{title:"IELTS Words",key:"ielts"},
	{title:"TOEIC Words",key:"toeic"},
	{title:"TOEFL Words",key:"toefl"},
	{title:"Irregular Verbs",key:"irregverbs"},
]

function CrucialWordsComponent(props) {
	const [data, setData] = useState(null);
	const [keyword, setKeyword] = useState("");
	const dispatch = useDispatch();

	useEffect(()=>{
		if(data){
			dispatch(clearErrors());
			dispatch(importantWordActions(data.key))
		}
	},[data])

	const ListWords = (props) => {
		const importantWordReducer = useSelector(state=>state.importantWord)
		const [list,setList] = useState([])
		const searchData = useSelector(state => state.search);
		const [wordDetail, setWordDetail] = useState(null)
		const [displayResult, setDisplayResult] = useState(false);
		const error = useSelector(state => state.error);

		const [searchResult, setSearchResult] = useState([]);
		const [recentSearch, ] = useState(localStorage.getItem('recent_search') ? JSON.parse(localStorage.getItem('recent_search')) : []);
		const [avBookmark, ] = useState(localStorage.getItem('av_bookmark') ? JSON.parse(localStorage.getItem('av_bookmark')) : []);
		const [searchType,] = useState( { text: "Anh - Việt", id: 0 } );

		useEffect(() => {
			if(!importantWordReducer.isFetching){
				setList(importantWordReducer.payload)
			}
		},[importantWordReducer.payload])

		const ModeComponent = () => {
			const [mode, setMode] = useState(null)

			const [sort, setSort] = useState({
				fn: undefined
			})

			const onSortAZ = () => {
				setSort({ fn: (a, b) => (a.word > b.word) ? 1 : (b.word > a.word) ? -1 : 0 })
			}

			const onBookmark = (vocabulary) => {
				// Call Apis here
				if(searchType.id === 0){
					saveWordToLocalStorageArray(vocabulary, 'av_bookmark');
				} else if(searchType.id === 1){
					saveWordToLocalStorageArray(vocabulary, 'va_bookmark');
				}
			}
			const onDelete = () => {
				// Call Apis here
				// setList([])
			}
			const onRemindAll = () => {
				// Call Apis here
				// let data = listData.map(e => { e.isBookmarked = true; return e })
				// setList(data)
			}
			const onDeleteRemindAll = () => {
				// Call Apis here
				// let data = listData.map(e => { e.isBookmarked = false; return e })
				// setList(data)
			}
			const handlePaginationChange = (e, { activePage }) => {
				dispatch(clearErrors())
				dispatch(clearImpotantWord())
				dispatch(importantWordActions(data.key,(activePage-1)*100))
			}

			const round = (number, roundRule)=>{
				return Math.trunc(number / roundRule) + 1
			}

			const findLocal = (vocabulary)=>{
				let listCode = "av_bookmark"
				let index = avBookmark.findIndex(item => item.word.toLowerCase() === vocabulary.word.toLowerCase())
				listCode = index === -1 ? "recent_search" : listCode
				index = index === -1 ? recentSearch.findIndex(item => item.word.toLowerCase() === vocabulary.word.toLowerCase()) : index
				if(index === -1){
					setKeyword(vocabulary.word)
					console.log(vocabulary.word)
				} else {
					setWordDetail(listCode === 'av_bookmark' ? avBookmark[index] : recentSearch[index])
					setDisplayResult(true);
				}
			}

			switch (mode) {
				case "quick-test":
					return <ReviewComponent listData={list} onGoBack={() => setMode(null)} />;
				case "listening":
					return <ImportantListeningPracticeComponent listData={list} onGoBack={() => setMode(null)} />;
				default:
					return (
						<section id={"crucial-words"}>
							{
								displayResult && wordDetail ?
									<SearchResultComponent isDisplay={displayResult} searchType={searchType} data={wordDetail}/>
									: ""
							}
							<div className={"action"}>
								<button className={"btn-quick-test"} onClick={() => setMode("quick-test")}>Kiểm tra nhanh</button>
								<button className={"btn-listening-practice"} onClick={() => setMode("listening")}>Luyện nghe</button>
							</div>
							<div className={"crucial-words-container"}>
								<>
									<div className={"header"}>
										<div className={"back"} onClick={() => {props.onGoBack()}}>
											<BackArrowIcon />
										</div>
										<div className={"title"}>
											{props.title}
										</div>
										<ImportantFilterComponent
											onSortAZ={() => onSortAZ()}
											onDelete={() => onDelete()}
											onRemindAll={() => onRemindAll()}
											onDeleteRemindAll={() => onDeleteRemindAll()} />
									</div>
									{
											error.status && error.id === IMPORTANT_WORD_COMPLETE
												? <Message error content={error.msg}/>
												: importantWordReducer.success && list.length
												?
												<div>
													<Pagination
														boundaryRange={0}
														siblingRange={1}
														activePage={importantWordReducer.page}
														onPageChange={handlePaginationChange}
														totalPages={round(importantWordReducer.count,100 )}
													/>
													<ImportantListVocabularyComponent
														sort={sort.fn}
														data={list}
														searchType={searchType}
														onClickItem={(vocabulary)=>{findLocal(vocabulary)}}
														onBookmark={(vocabulary) => onBookmark(vocabulary)} />
													<Pagination
														boundaryRange={1}
														siblingRange={1}
														activePage={importantWordReducer.page}
														onPageChange={handlePaginationChange}
														totalPages={round(importantWordReducer.count,100 )}
													/>
												</div>
												: importantWordReducer.isRequesting ?
													<div style={{padding: "30px",minHeight:"90vh"}}>Loading...</div>
													:<div style={{padding: "30px"}}>Danh sách trống!</div>
									}
										</>
								</div>
						</section>
					)
			}
		};

		return <ModeComponent />
	};

	return (
		<>
			<VdictDictionaryComponent />
			<SearchComponent keyword={keyword}/>
			{
				data ?
					<ListWords title={data.title} key={data.key} onGoBack={() => {dispatch(clearImpotantWord());setData(null)}} />
					:
					<section id={"crucial-words"}>
						<div className={"crucial-words-container"}>
							<div className={"header"}>
								<div className={"title"}> Những từ quan trọng </div>
							</div>
							<div className={"content"} >
								{
									listData.map(item=>{
										return <div className={"item"} onClick={() => setData(item)}>
											<div>
												<VocabularyIcon />
												<p>{item.title}</p>
											</div>
											<ArrowRightIcon style={{ width: "15px", height: "15px" }} />
										</div>
									})
								}
							</div>
						</div>
					</section>
			}
		</>
	)
}

export default CrucialWordsComponent
