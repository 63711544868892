import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './SpeakerComponent.css'
import {ReactComponent as SpeakerIcon} from "../../assets/icons/speaker.svg";

SpeakerComponent.propTypes = {
	play: PropTypes.bool.isRequired,
	audio: PropTypes.string.isRequired,
	lang: PropTypes.string.isRequired
};
SpeakerComponent.defaultProps = {
	lang: 'uk',
	play: false
};

function SpeakerComponent(props) {
	const [isPlaying,setIsPlaying] = useState(false)
	const [audio,setAudio] = useState(null)
	const [isFirst,setIsFirst] = useState(true)
	const OnPronounce = ()=>{
		play()
	}

	useEffect(()=>{
		setAudio(new Audio(props.audio))
		setIsFirst(true)
		return ()=>{setAudio(null)}
	},[props.audio])

	const play = () => {
		if(audio){
			audio.play().then(()=>{
				setIsPlaying(true)
			})
			audio.onended = () => {
				setIsPlaying(false)
			}
		}
	}

	if(props.play && isFirst){
		play()
		setIsFirst(false)
	}

	return (
		<div className="speaker" >
			<button className="svg-icon" disabled={isPlaying} onClick={() => OnPronounce()}>
				<SpeakerIcon />
			</button>
		</div>
	);
}

export default SpeakerComponent;