export function saveWordToLocalStorageArray(data, arrayKey) {
    let array;
    //is anything in localstorage?
    if (localStorage.getItem(arrayKey) === null) {
        array = [];
    } else {
        // Parse the serialized data back into an array of objects
        array = JSON.parse(localStorage.getItem(arrayKey));
    }
    // Push the new data (whether it be an object or anything else) onto the array
    if(!(array.some(word => (word.id === data.id)))){
        array.unshift(data);
        localStorage.setItem(arrayKey, JSON.stringify(array));
    }
}
