import React from "react";
import PropTypes from "prop-types";
import './ListVocabularyComponent.css';
import ItemVocabularyComponent from "../../recent-words/components/ItemVocabularyComponent";

function ListVocabularyComponent({ data, sort, searchType, onClickItem }) {
    return (
        <div className={"content"}>
            {
                data.sort(sort).map(vocabulary =>
                    <ItemVocabularyComponent key={vocabulary.id} onClick={()=>onClickItem()} vocabulary={vocabulary} searchType={searchType}/>
                )
            }
        </div>
    )
}

ListVocabularyComponent.propTypes = {
	data: PropTypes.array.isRequired
};

export default ListVocabularyComponent
