import React from "react";
import PropTypes from "prop-types";
import './ImportantListVocabularyComponent.css';
import ImportantItemVocabularyComponent from "./ImportantItemVocabularyComponent";

function ImportantListVocabularyComponent({ data, sort, searchType, onClickItem }) {
	return (
		<div className={"content"}>
			{
				data.sort(sort).map(vocabulary =>
					<ImportantItemVocabularyComponent key={vocabulary.id} onClick={()=>onClickItem(vocabulary)} vocabulary={vocabulary} searchType={searchType}/>
				)
			}
		</div>
	)
}

ImportantListVocabularyComponent.propTypes = {
	data: PropTypes.array.isRequired
};

export default ImportantListVocabularyComponent
