import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Image, Container, Loader} from 'semantic-ui-react';
import Validator from 'validator'
import SampleMessage from '../messages/SampleMessage'
import ErrorMessage from '../messages/ErrorMessage'
import {ReactComponent as UserIcon} from '../../../assets/icons/user.svg'
import {ReactComponent as AddIcon} from '../../../assets/icons/plus.svg'
import './SignupComponent.css'
import {Message} from 'semantic-ui-react';
import {signup} from '../../../actions/signup/signupActions';
import {clearErrors} from "../../../actions/errorAction";
import {useSelector, useDispatch} from "react-redux";
import {SIGNUP_COMPLETE} from "../../../actions/types";
import RestorePopup from "./RestorePopup";

function SignupComponent(props) {
    const [error, setError] = useState({})
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [isShowRestore, setIsShowRestore] = useState(false);
    const [acceptChecked, setAcceptChecked] = useState(true)
    const dispatch = useDispatch();
    const haveNameError = () => {
        return !Validator.isLength(userName, {min: 3, max: undefined})
    }
    const haveEmailError = () => {
        return !Validator.isEmail(email)
    }
    const havePasswordError = () => {
        return Validator.isEmpty(password)
    }
    const haveConfirmError = () => {
        return confirm !== password
    }
    const nameInputClass = error.userName ? "error" : ""
    const emailInputClass = error.email ? "error" : ""
    const passInputClass = error.password ? "error" : ""
    const errorMsg = useSelector(state => state.error);
    const signUp = useSelector(state => state.signUp);

    const onEmailChange = e => {
        setEmail(e.target.value);
        setError({})
    }
    const onPasswordChange = e => {
        setPassword(e.target.value);
        setError({})
    }
    const onConfirmChange = e => {
        setConfirm(e.target.value);
        setError({})
    }
    const onUserNameChange = e => {
        setUserName(e.target.value);
        setError({})
    }

    useEffect(() => {
        if(signUp.success){
            if(localStorage.getItem('av_bookmark') ||
                localStorage.getItem('va_bookmark') ||
                localStorage.getItem('recent_search') ||
                localStorage.getItem('av_note') ||
                localStorage.getItem('va_note')){
                setIsShowRestore(true);
            } else {
                window.location.reload();
            }
        }
    }, [signUp.success]);

    const isEmpty = (o) => {
        for (let i in o) {
            if (o.hasOwnProperty(i)) return false;
        }
        return true;
    }

    const onSubmit = () => {
        const newError = validate()
        if (isEmpty(newError)) {
            dispatch(clearErrors());
            dispatch(signup(email, password, confirm, userName));
        } else
            setError(newError)
    }

    const validate = () => {
        const error = {}
        if (haveNameError()) error.userName = "Vui lòng nhập tên"
        if (haveEmailError()) error.email = "Sai email"
        if (havePasswordError()) error.password = "Sai mật khẩu"
        if (haveConfirmError()) error.confirm = "Mật khẩu không khớp"
        return error;
    }

    return (
        isShowRestore ? <RestorePopup open={isShowRestore}/>
        :
        <Container className="login component">
            <Image className="logo" src='logo.png' size='small' wrapped centered/>
            <h3 className="header">ĐĂNG KÝ</h3>
            <div className="icon-container">
                <UserIcon className="icon-user"/>
                <AddIcon className="icon-add"/>
            </div>
            <form className="form" action="">
                <div style={{display: "inline-block"}}>
                    <div className="field-container">
                        <input className={nameInputClass} id="username" name="username" type="text" autoComplete="off"
                               placeholder="Tên đăng nhập" value={userName} onChange={e => onUserNameChange(e)}>
                        </input>
                        <ErrorMessage message={error.userName}/>
                    </div>
                    <div className="field-container">
                        <input className={emailInputClass} id="email" name="email" type="text" autoComplete="off"
                               placeholder="Email" value={email} onChange={e => onEmailChange(e)}>
                        </input>
                        <SampleMessage
                            show={(Validator.isEmpty(email) ? error.email === undefined : false) || (!haveEmailError)}
                            message="VD: abc@xyz.com"/>
                        <ErrorMessage message={error.email}/>
                    </div>
                    <div className="field-container">
                        <input className={passInputClass} id="password" name="password" type="password"
                               autoComplete="off"
                               placeholder="Mật khẩu" value={password} onChange={e => onPasswordChange(e)}>
                        </input>
                        <ErrorMessage message={error.password}/>
                    </div>
                    <div className="field-container">
                        <input className={passInputClass} id="confirm" name="confirm" type="password" autoComplete="off"
                               placeholder="Nhập lại mật khẩu" value={confirm} onChange={e => onConfirmChange(e)}>
                        </input>
                        <ErrorMessage message={error.confirm}/>
                    </div>
                    <label className="checkbox-container">Khi sử dụng Vdict, nghĩa là bạn đã đồng ý với các<br/> Điều
                        khoản và Điều kiện của chúng tôi.
                        <input type="checkbox" checked={acceptChecked} onChange={() => {
                            setAcceptChecked(!acceptChecked)
                        }}/>
                        <span className="checkmark"/>
                    </label>
                    {
                        errorMsg.status && errorMsg.id === SIGNUP_COMPLETE ?
                            errorMsg.msg.length ?
                                <Message error list={errorMsg.msg}/>
                                : ""
                            : ""
                    }
                    <button type="button" disabled={!acceptChecked} className="signup-button"
                            onClick={() => onSubmit()}>
                        {
                            signUp.isSigningUp ?
                                <Loader active inline inverted/>
                                :
                                <>Đăng ký</>
                        }</button>
                </div>
            </form>
        </Container>
    )
}

SignupComponent.defaultProps = {
    name: "Sign Up Component"
}

SignupComponent.propTypes = {
    name: PropTypes.string.isRequired
}

export default SignupComponent

