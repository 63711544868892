import {TRANSLATE_REQUEST, TRANSLATE_COMPLETE} from "../../actions/types";


const initialState = {
};

export default function(state = initialState, action) {
	switch(action.type) {
		case TRANSLATE_REQUEST:
			return {
				...state,
				isRequesting: true
			};
		case TRANSLATE_COMPLETE:
			return {
				...state,
				isRequesting: false,
				success: action.success,
				payload: action.payload,
				error: action.error
			};
		default:
			return state;
	}
}
