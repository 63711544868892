import {ReactComponent as SearchIcon} from "../../../assets/icons/search.svg";
import {ReactComponent as AngleDownIcon} from "../../../assets/icons/angle-down.svg";
import {Button} from "semantic-ui-react";
import SuggestionResultItemComponent from "./SuggestionResultItemComponent";
import React, {useEffect, useRef, useState} from "react";
import "./SearchComponent.css";
import SearchResultComponent from "./SearchResultComponent";
import {useDispatch, useSelector} from "react-redux";
import {search, searchWordDetail} from "../../../actions/search/searchAction";
import { Loader } from 'semantic-ui-react'
import {SEARCH_WORD_COMPLETE, SEARCH_WORD_DETAIL_COMPLETE} from "../../../actions/types";
import {saveWordToLocalStorageArray} from "../../../utils/saveWordToLocalStorageArray";

SearchComponent.defaultProps = {
    displayResult: false,
    keyword:""
}

function SearchComponent(props) {
    const [isDisplayDropdownContent, setDisplayDropdownContent] = useState(false);
    const [keyword, setKeyword] = useState(props.keyword);
    const [displayResult, setDisplayResult] = useState(props.displayResult);
    const [wordDetail, setWordDetail] = useState(null);
    const [recentList, setRecentList] = useState([]);
    const [searchTypeList, setSearchTypeList] = useState([
        {
            text: "Anh - Việt",
            id: 0
        },
        {
            text: "Việt - Anh",
            id: 1
        }
    ]);
    const [searchType, setSearchType] = useState(0);
    const dispatch = useDispatch();
    const searchData = useSelector(state => state.search);

    useEffect(() => {
        if(keyword.trim() !== ""){
            dispatch(search(keyword.trim(), searchType))
        }
    }, [dispatch, keyword, searchType]);

    useEffect(() => {
        setKeyword(props.keyword)
    }, [props.keyword]);

    useEffect(() => {
        if(wordDetail !== null){
            dispatch(searchWordDetail(wordDetail.id, wordDetail.type))
        }
    }, [dispatch, wordDetail]);

    const filterRef = useRef(null);
    useOutsideAlerter(filterRef);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDisplayDropdownContent(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const onClickWord = (word) => {
        setWordDetail(word);
        // setKeyword(word.word);
        setDisplayResult(true);
        saveWordToLocalStorageArray(word, 'recent_search');
    };
    const onClickSearchType = (e, id) => {
        e.preventDefault();
        setSearchType(id);
        setDisplayResult(false);
    };

    return (
        <>
            <div className={"suggestion-container"}>
                <div className={"suggestion-wrapper"}>
                    <div className={"suggestion-action"}>
                        <div className={"suggestion-box"}>
                            <SearchIcon/>
                            <input type={"text"}
                                   value={keyword}
                                   onChange={(e) => {
                                        setDisplayResult(false);
                                        setKeyword(e.target.value);
                                       if(e.target.value.trim() === "" && localStorage.getItem('recent_search')){
                                           setRecentList(JSON.parse(localStorage.getItem('recent_search')));
                                       }
                                   }}
                                   onFocus={(e) => {
                                       if(e.target.value.trim().trim() === "" && localStorage.getItem('recent_search')){
                                           setRecentList(JSON.parse(localStorage.getItem('recent_search')));
                                       }
                                   }}
                            />
                        </div>
                        <div className={"dropdown"} ref={filterRef}>
                            <div className={"filter"} onClick={()=>setDisplayDropdownContent(!isDisplayDropdownContent)}>
                                <span>{searchTypeList[searchType].text}</span> <AngleDownIcon/>
                            </div>
                            <div className={"dropdown-content"}
                                 onClick={()=>setDisplayDropdownContent(false)}
                                 style={isDisplayDropdownContent? {display: "block"} : {display: "none"}}>
                                {
                                    searchTypeList ? searchTypeList.map((item, index) => <a key={index} href={"#"} onClick={e => onClickSearchType(e, item.id)}>{item.text}</a>) : ""
                                }
                            </div>
                        </div>
                        <Button className={"btn-suggestion"} onClick={()=>setDisplayResult(true)}>Tra từ</Button>
                    </div>
                    {
                        displayResult ? <></>
                            :
                            <div className={"suggestion-result-wrapper"}>
                                <div className={"suggestion-result"}>
                                    {
                                        keyword.trim() !== ""
                                            ?
                                                (!searchData.isFetching) && searchData.type === SEARCH_WORD_COMPLETE && searchData.success ?
                                                searchData.data.map((item,index) => {
                                                    return(
                                                        <SuggestionResultItemComponent key={index} onClickItem={()=> onClickWord(item)}
                                                            vocabulary={item}/>
                                                    )
                                                })
                                                :
                                                <div style={{"padding": "30px 0", textAlign: "center"}}>
                                                    <Loader active inline/>
                                                </div>
                                            :
                                            recentList.map((item,index) => {
                                                return(
                                                    <SuggestionResultItemComponent isRecent key={index} onClickItem={()=> onClickWord(item)}
                                                                                   vocabulary={item}/>
                                                )
                                            })

                                    }
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                displayResult ?
                    searchData.success && !searchData.isFetching && searchData.type === SEARCH_WORD_DETAIL_COMPLETE ?
                        <SearchResultComponent isDisplay={displayResult} searchType={searchTypeList[searchType]} data={searchData.data}/>
                        : ""
                : ""
            }
        </>
    )
}

export default SearchComponent
