import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SampleMessage from '../messages/SampleMessage.js'
import ErrorMessage from '../messages/ErrorMessage.js'
import { Message } from 'semantic-ui-react';
import Validator from 'validator'
import './LoginForm.css'
import {clearErrors} from "../../../actions/errorAction";
import {useSelector, useDispatch} from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { withRouter } from "react-router-dom";
import {LOGIN_COMPLETE} from "../../../actions/types";

function LoginForm(props) {
	const [phoneNumber, setPhoneNumber] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState({});
	const [errorMsg, setErrorMsg] = useState({ ...props.error });
	const dispatch = useDispatch();
	const login = useSelector(state => state.login);
	if(login.success){
		window.location.reload();
	}
	useEffect(() => {
		setErrorMsg(props.error);
	}, [props.error]);

	const havePhoneError = () => { return Validator.isEmpty(phoneNumber) || !Validator.isEmail(phoneNumber) }
	const havePasswordError = () => { return Validator.isEmpty(password) || !Validator.isLength(password, { min: 5, max: undefined }) }
	const phoneInputClass = error.phoneNumber ? "error" : ""
	const passInputClass = error.password ? "error" : ""

	const onPhoneChange = e => { setPhoneNumber(e.target.value); setError({}) }
	const onPasswordChange = e => { setPassword(e.target.value); setError({}) }

	const isEmpty = (o) => {
		for (var i in o) {
			if (o.hasOwnProperty(i)) return false;
		}
		return true;
	}

	const onSubmit = () => {
		const newError = validate()
		if (isEmpty(newError)){
			dispatch(clearErrors());
			props.submit({ email: phoneNumber, password: password })
		}
		else
			setError(newError)
	}

	const validate = () => {
		const error = {}
		if (havePhoneError()) error.phoneNumber = "Sai email"
		if (havePasswordError()) error.password = "Sai mật khẩu"
		return error;
	}

	return (
		<form className="form" action="" >
			<div style={{ display: "inline-block" }}>
				<div className="field-container" >
					<input className={phoneInputClass} id="phone" name="phone" type="text"
					       placeholder="Email" value={phoneNumber} onChange={e => onPhoneChange(e)}>
					</input>
					<SampleMessage show={(Validator.isEmpty(phoneNumber) ? error.phoneNumber === undefined : false) || (!havePhoneError)} message="VD: abc@xyz.com" />
					<ErrorMessage message={error.phoneNumber} />
				</div>
				<div className="field-container">
					<input className={passInputClass} id="password" name="password" type="password"
					       placeholder="Mật khẩu" value={password} onChange={e => onPasswordChange(e)}>
					</input>
					<ErrorMessage message={error.password} />
				</div>
				{
					errorMsg.status && errorMsg.id === LOGIN_COMPLETE ?
						errorMsg.msg.non_field_errors ?
							<Message color='red'>Email hoặc mật khẩu không chính xác!</Message>
							: ""
						: ""
				}
				<button type="button" className="login-button" onClick={() => onSubmit()}>
					{
						login.isLoggingIn ?
							<Loader active inline inverted/>
							:
							<>Đăng nhập</>
					}
				</button>
				<a className="forget-password" onClick={()=>{props.history.push('/password/reset')}}>Quên mật khẩu?</a>
			</div>
		</form >
	)
}

LoginForm.defaultProps = {
	name: "Login Form",
	error: {}
}

LoginForm.propTypes = {
	name: PropTypes.string,
	submit: PropTypes.func.isRequired,
	error: PropTypes.object
}

export default withRouter(LoginForm)

