import {LESSON_WORD_REQUEST, LESSON_WORD_COMPLETE} from "../../actions/types";


const initialState = {
};

export default function(state = initialState, action) {
	switch(action.type) {
		case LESSON_WORD_REQUEST:
			return {
				...state,
				isRequesting: true
			};
		case LESSON_WORD_COMPLETE:
			return {
				...state,
				isRequesting: false,
				success: action.success,
				payload: action.payload
			};
		default:
			return state;
	}
}
