import React from 'react'
import PropTypes from 'prop-types'
import "./SampleMessage.css"

function SampleMessage(props) {
    return (
        <div className={props.show ? "" : "hidden"}>
            <span>{props.message}</span>
        </div>
    )
}

SampleMessage.defaultProps = {
    show: false
}

SampleMessage.propTypes = {
    show: PropTypes.bool
}

export default SampleMessage

